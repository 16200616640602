import axios from 'axios'

export const axiosGis = axios.create({
  timeout: 20000,
  baseURL: process.env.REACT_APP_URL_GIS_API,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json'
  }
})
