import Box from '@mui/material/Box'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'

import { TaskTableData } from '../ReviewTasksView'

interface HeadCell {
  disablePadding: boolean
  id: keyof TaskTableData
  label: string
  numeric: boolean
}

const headCells: readonly HeadCell[] = [
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status'
  },
  {
    id: 'OrganizationName',
    numeric: false,
    disablePadding: true,
    label: 'Organização'
  },
  {
    id: 'PersonName',
    numeric: false,
    disablePadding: true,
    label: 'Perfil'
  },
  {
    id: 'PropertyName',
    numeric: false,
    disablePadding: true,
    label: 'Propriedade'
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: true,
    label: 'Tipo'
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: true,
    label: 'Criado em'
  }
]

interface EnhancedTableProps {
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof TaskTableData) => void
  order: 'asc' | 'desc'
  orderBy: string
  rowCount: number
}

export const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { order, orderBy, onRequestSort } = props
  const createSortHandler =
    (property: keyof TaskTableData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'decrescente' : 'crescente'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
