/**
 * @Important
 *
 * Use the following terms to refer to:
 *
 * - HUB Area (Talhão) = landField;
 * - HUB Season in the chart = chartArea;
 * - HUB Season in the table = tableArea; (tableArea = chartArea + table payload, so it can be used as chartArea)
 * - HUB Property = the entire code refers to a specific property, so it is unnecessary;
 */

import { openConfirm } from '@components'
import { colors } from '@mui/material'
import Box from '@mui/material/Box'
import * as turf from '@turf/turf'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { TData } from '../../../components/AreasChart/AreasChartContainer'
import { AreasChartTable } from '../../../components/AreasChartTable/AreasChartTableContainer'
import { TTableArea } from '../../../components/AreasTable/AreasTableView'
import { changeHeader } from '../../../redux/header'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { RootState } from '../../../redux/store'
import { BenderApi } from '../../../services/BenderApi'
import { HubApi, THubProperty } from '../../../services/HubApi'
import { LutienApi, TCrop } from '../../../services/LutienApi'
import { BENDER_TASK_STATUS } from '../../../services/enums/bender.enum'
import { UnidentifiedCrop } from '../../../shared/constants/unidentifiedCrop'
import { ShareSeasonsModal } from './components/ShareSeasonsModal'
import { Sidebar } from './components/Sidebar'
import { useFetchDetectedSeasons } from './hooks/useFetchDetectedSeasons'
import { useFetchPropertyNdvi } from './hooks/useFetchPropertyNdvi'

/* -------------------- Types -------------------- */

export type TLandField = {
  id: string
  name: string
  geometry: turf.Geometry
}

/* -------------------- Start of Main Component -------------------- */

export const EditSeasons = () => {
  const [ndviData, setNdviData] = useState<TData[]>([])
  const [tableAreas, setTableAreas] = useState<TTableArea[]>([])
  const [selectedLandFieldId, setSelectedLandFieldId] = useState<string>()
  const [openShareModal, setOpenShareModal] = useState(false)
  const [selectedAreaCoordinates, setSelectedAreaCoordinates] =
    useState<turf.helpers.Position | null>(null)
  const [cultivationAreas, setCultivationAreas] = useState<{ id: string; name: string }[]>([]) // TODO: cultivationAreas = landFields!!

  const [cropsCatalog, setCropsCatalog] = useState<TCrop[]>([])
  const [property, setProperty] = useState<THubProperty>()
  const [isLoading, setIsLoading] = useState(true)
  const pageOpeningTime = useRef(new Date())

  const { property_id: propertyId, task_id: taskId } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const { ndviCatalog, landFields, fetchNdvi, isLoading: isLoadingNdvi } = useFetchPropertyNdvi()
  const { detectedSeasons, isLoading: isLoadingDetectedSeasons } =
    useFetchDetectedSeasons(propertyId)

  const userInfo = useAppSelector((state: RootState) => state.authenticator.user.info)

  /* -------------------- API Requests -------------------- */

  const fetchProperty = useCallback((propertyId: string) => HubApi.getProperty(propertyId), [])

  /* -------------------- Several Functions -------------------- */

  function buildHubSeasonsFromAreas() {
    return tableAreas.map(area => {
      const cultAreaName =
        cultivationAreas.find(cultArea => cultArea.id === area.landFieldId)?.name || ''

      const cropName = (area.infos.find(({ label }) => label === 'Cultura')?.value ||
        'Não identificado') as string

      const cropId = cropsCatalog.find(crop => crop.cropName === cropName)?.cropId

      return {
        crop_name: cropName,
        crops_id: cropId,
        area_id: area?.landFieldId,
        area_name: cultAreaName,
        start_date: `${area.xMin.value.getDate()}-${
          area.xMin.value.getMonth() + 1
        }-${area.xMin.value.getFullYear()}`,
        end_date: `${area.xMax.value.getDate()}-${
          area.xMax.value.getMonth() + 1
        }-${area.xMax.value.getFullYear()}`,
        validation: 'Check NDVI'
      }
    })
  }

  function shareSeasonsBetweenLandFields(
    ownerLandFieldId: string,
    recipientLandFieldIds: string[]
  ) {
    const seasonsToShare = tableAreas.filter(area => area.landFieldId === ownerLandFieldId)
    const newSeasons = tableAreas.filter(area => !recipientLandFieldIds.includes(area.landFieldId))
    recipientLandFieldIds.forEach(recipientLandFieldId => {
      seasonsToShare.forEach(season => {
        newSeasons.push({
          ...season,
          landFieldId: recipientLandFieldId
        })
      })
    })
    setTableAreas(newSeasons.map((season, i) => ({ ...season, id: i + 1 })))
  }

  /* -------------------- Handlers -------------------- */

  const onSelectCultArea = async (areaId: string) => {
    if (!areaId) return

    setSelectedLandFieldId(areaId)
    setNdviData(fetchNdvi(areaId))

    const selectedPropertyAreaGeometry = landFields.find(
      propertyArea => propertyArea.id == selectedLandFieldId
    )?.geometry

    selectedPropertyAreaGeometry !== undefined
      ? setSelectedAreaCoordinates(turf.centroid(selectedPropertyAreaGeometry).geometry.coordinates)
      : setSelectedAreaCoordinates(null)
  }

  function onCancelReview() {
    if (confirm('Deseja cancelar a revisão?')) navigate('/bender/tasks')
  }

  async function onSubmitReview() {
    if (isLoading) return
    const reviewedSeasons = buildHubSeasonsFromAreas()

    if (await openConfirm('Você deseja finalizar a tarefa?', { title: 'Finalizar tarefa' })) {
      try {
        await BenderApi.saveTasks(taskId ?? '', {
          completedBy: userInfo.email,
          completionInput: reviewedSeasons,
          id: taskId ?? '',
          status: BENDER_TASK_STATUS.SUCCEEDED,
          startedAt: pageOpeningTime.current
        })
        enqueueSnackbar('Tarefa finalizada com sucesso!', { variant: 'success' })
        navigate('/bender/tasks')
      } catch (err) {
        enqueueSnackbar('Ocorreu um erro ao tentar finalizar a tarefa.', { variant: 'error' })
        console.error(err)
      }
    }
  }

  /* -------------------- UseEffects -------------------- */

  useEffect(() => {
    onPageRender()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function onPageRender() {
    dispatch(changeHeader({ mode: 'none' }))
    setCropsCatalog([...(await LutienApi.getCrops()), UnidentifiedCrop])
  }

  const onPageLoaded = async () => {
    onSelectCultArea(landFields?.[0]?.id || '')
  }

  useEffect(() => {
    if (propertyId) {
      fetchProperty(propertyId).then(res => {
        setProperty({
          id: res.id,
          name: res.name
        })
      })
    }
  }, [fetchProperty, propertyId])

  useEffect(() => {
    if (isLoadingDetectedSeasons || isLoadingNdvi) return

    setIsLoading(false)
    onPageLoaded()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingDetectedSeasons, isLoadingNdvi])

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        bgcolor: colors.grey[50]
      }}>
      <Box
        style={{
          height: '100vh',
          width: '330px'
        }}>
        <ShareSeasonsModal
          open={openShareModal}
          data={ndviData}
          areas={tableAreas.filter(
            ({ landFieldId: cultAreaId }) => cultAreaId === selectedLandFieldId
          )}
          propertyAreas={landFields}
          onClose={() => setOpenShareModal(false)}
          originAreaId={selectedLandFieldId || ''}
          shareSeasonsBetweenAreas={shareSeasonsBetweenLandFields}
          selectedAreaCoordinates={selectedAreaCoordinates}
          ndviCatalog={ndviCatalog}
        />
        <Sidebar
          property={property}
          areas={landFields}
          selectedAreaId={selectedLandFieldId || ''}
          onSelectArea={onSelectCultArea}
          onSubmitReview={onSubmitReview}
          onCancelReview={onCancelReview}
        />
      </Box>
      <AreasChartTable
        ndviData={ndviData}
        tableAreas={tableAreas}
        selectedLandFieldId={selectedLandFieldId}
        setOpenShareModal={setOpenShareModal}
        setTableAreas={setTableAreas}
        cropsCatalog={cropsCatalog}
        detectedSeasons={detectedSeasons}
        cultivationAreas={cultivationAreas}
        setCultivationAreas={setCultivationAreas}
        isLoading={isLoading}
      />
    </Box>
  )
}
