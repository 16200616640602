export enum BENDER_TASK_STATUS {
  SUCCEEDED = 'SUCCEEDED',
  PENDING = 'PENDING',
  FAILED = 'FAILED'
}

export enum BENDER_TASK_TYPE {
  SEGMENTATION_REVIEW = 'SEGMENTATION_REVIEW',
  PROFILE_REVIEW = 'PROFILE_REVIEW',
  DETECT_SEASON_REVIEW = 'DETECT_SEASON_REVIEW',
  FISCAL_COMPLIANCE_REVIEW = 'FISCAL_COMPLIANCE_REVIEW',
  CRIMINAL_COMPLIANCE_REVIEW = 'CRIMINAL_COMPLIANCE_REVIEW',
  LABOR_COMPLIANCE_REVIEW = 'LABOR_COMPLIANCE_REVIEW',
  ENVIRONMENTAL_COMPLIANCE_REVIEW = 'ENVIRONMENTAL_COMPLIANCE_REVIEW'
}

export enum BENDER_EXECUTION_STATUS {
  RUNNING = 'RUNNING',
  ABORTED = 'ABORTED',
  FAILED = 'FAILED',
  SUCCEEDED = 'SUCCEEDED',
  PENDING = 'PENDING'
}
