import { HubApi } from '@services'
import { AxiosError } from 'axios'
import { useState } from 'react'

import { COMPLIANCE_TASK_TYPE } from '../enums/ComplianceTaskType'
import { IDeleteRecord } from '../providers/ComplianceContext'

export const useDeleteRecordsBackend = (complianceTaskType: COMPLIANCE_TASK_TYPE) => {
  const [error, setError] = useState<AxiosError | null>()
  const [isRequesting, setIsRequesting] = useState<boolean>()
  const [success, setSuccess] = useState<boolean>()

  const resetStates = () => {
    setError(undefined)
    setIsRequesting(undefined)
    setSuccess(undefined)
  }

  const bootstrap = async (deleteRecords: IDeleteRecord[]) => {
    try {
      resetStates()
      setIsRequesting(true)

      if (complianceTaskType === COMPLIANCE_TASK_TYPE.criminal) {
        await Promise.all(
          deleteRecords.map(deleteRecord =>
            HubApi.AssessmentsCriminal.deleteCriminalReportRequest({
              criminalRecordId: deleteRecord.id
            })
          )
        )
      } else if (complianceTaskType === COMPLIANCE_TASK_TYPE.environmental) {
        await Promise.all(
          deleteRecords.map(deleteRecord => {
            if (deleteRecord.endpoint === 'Environmental Debts')
              return HubApi.AssessmentsEnvironmental.deleteDebtRequest({
                environmentalDebtId: deleteRecord.id
              })

            if (deleteRecord.endpoint === 'Environmental Embargoes')
              return HubApi.AssessmentsEnvironmental.deleteEmbargoRequest({
                environmentalEmbargoId: deleteRecord.id
              })
          })
        )
      } else if (complianceTaskType === COMPLIANCE_TASK_TYPE.fiscal) {
        await Promise.all(
          deleteRecords.map(deleteRecord =>
            HubApi.AssessmentsFiscal.deleteDebtInquiryRequest({ debtInquiryId: deleteRecord.id })
          )
        )
      } else if (complianceTaskType === COMPLIANCE_TASK_TYPE.labor) {
        await Promise.all(
          deleteRecords.map(deleteRecord => {
            if (deleteRecord.endpoint === 'FGTS')
              return HubApi.AssessmentsLabor.deleteFGTSDebtRequest({
                fgtsDebtId: deleteRecord.id
              })

            if (deleteRecord.endpoint === 'CNDT')
              return HubApi.AssessmentsLabor.deleteLaborDebtRequest({
                laborDebtId: deleteRecord.id
              })
          })
        )
      }
      setError(null)
      setSuccess(true)
    } catch (err) {
      if (err instanceof AxiosError) setError(err)
      setSuccess(false)
    } finally {
      setIsRequesting(false)
    }
  }

  const deleteRecordsBackend = (deleteRecords: IDeleteRecord[]) => {
    bootstrap(deleteRecords)
  }

  return { deleteRecordsBackend, error, isRequesting, success }
}
