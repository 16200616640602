import { Writeable } from '@/utils/Writeable'
import { BenderApi } from '@services'
import { createContext, ReactNode, useEffect, useRef, useState } from 'react'
import { FileObject } from 'react-mui-dropzone'
import { useParams } from 'react-router-dom'

import { fiscalRecordTypeCatalog } from '../ComplianceFiscal/helpers/fiscalRecordTypeCatalog'
import { COMPLIANCE_TASK_TYPE } from '../enums/ComplianceTaskType'
import { emptyComplianceContext } from './helpers/emptyComplianceContext'

export const recordTypeCatalog = {
  criminal: ['Compliance Criminal'],
  environmental: ['Environmental Debts', 'Environmental Embargoes'],
  fiscal: Object.keys(fiscalRecordTypeCatalog),
  labor: ['CNDT', 'FGTS']
} as const

export const documentStatusCatalog = {
  criminal: ['POSITIVE', 'NEGATIVE', 'UNDEFINED'],
  environmental: ['POSITIVE', 'NEGATIVE', 'POSITIVE_WITH_NEGATIVE_EFFECTS', 'UNDEFINED'],
  fiscal: ['POSITIVE', 'NEGATIVE', 'POSITIVE_WITH_NEGATIVE_EFFECTS', 'UNDEFINED'],
  labor: ['POSITIVE', 'NEGATIVE', 'UNDEFINED']
} as const

export type TPersonProfile = {
  canonicalProfileId?: string
  govRegCode?: string
  govRegType?: 'LEGAL' | 'NATURAL'
  id?: string
  name?: string
}

/**
 * A record candidate is a generic compliance record created by the user, but not yet posted to the back-end.
 */
export interface IRecordCandidate {
  index?: number
  recordType: Writeable<typeof recordTypeCatalog[keyof typeof recordTypeCatalog][number]>
  documentStatus: Writeable<
    typeof documentStatusCatalog[keyof typeof documentStatusCatalog][number]
  >
  documentExpirationDate: Date
  file: FileObject
}

export interface IDeleteRecord {
  id: string
  endpoint?: Writeable<typeof recordTypeCatalog[keyof typeof recordTypeCatalog][number]>
}

export interface IComplianceContext {
  personProfile: TPersonProfile
  taskType: COMPLIANCE_TASK_TYPE
  recordCandidates: IRecordCandidate[]
  addRecordCandidate: (newRecordCandidate: IRecordCandidate) => void
  removeRecordCandidate: (recordCandidateIndex: number) => void
  deleteRecords: IDeleteRecord[]
  addDeleteRecord: (record: IDeleteRecord) => void
  hasPendingModifications: boolean
  resetStates: () => void
  resetCounter: number
}

export const ComplianceContext = createContext<IComplianceContext>(emptyComplianceContext)

export const ComplianceProvider = ({ children }: { children: ReactNode }) => {
  const { taskType, taskId } = useParams() as { taskType: COMPLIANCE_TASK_TYPE; taskId: string }

  const [recordCandidates, setRecordCandidates] = useState<IRecordCandidate[]>([])
  const [deleteRecords, setDeleteRecords] = useState<IDeleteRecord[]>([])
  const [personProfile, setPersonProfile] = useState<TPersonProfile>(
    emptyComplianceContext.personProfile
  )
  const [hasPendingModifications, setHasPendingModifications] = useState(false)
  const [resetCounter, setResetCounter] = useState(0)
  const index = useRef(0)

  const resetStates = () => {
    setRecordCandidates([])
    setDeleteRecords([])
    setHasPendingModifications(false)
    setResetCounter(prevResetCounter => prevResetCounter + 1)
    index.current = 0
  }

  const addRecordCandidate = (newRecordCandidate: IRecordCandidate) => {
    setRecordCandidates(prevRecordCandidates => [
      ...prevRecordCandidates,
      { ...newRecordCandidate, index: index.current }
    ])
    index.current += 1
  }

  const removeRecordCandidate = (recordCandidateIndex: number) => {
    setRecordCandidates(prevRecordCandidates =>
      prevRecordCandidates.filter(({ index: id }) => id !== recordCandidateIndex)
    )
  }

  const addDeleteRecord = (record: IDeleteRecord) => {
    setDeleteRecords(prevDeleteRecords => [...prevDeleteRecords, record])
  }

  const onPageRender = async () => {
    const { tasks } = await BenderApi.searchTasks({ filter: { id: taskId }, fields: ['input'] })
    const personInfo = tasks[0].input.PersonInfo
    setPersonProfile({
      canonicalProfileId: personInfo?.PersonCanonicalId,
      govRegCode: personInfo?.PersonGovRegCode,
      govRegType: personInfo?.PersonType,
      id: personInfo?.PersonId,
      name: personInfo?.PersonName
    })
  }

  useEffect(() => {
    setHasPendingModifications(recordCandidates.length > 0 || deleteRecords.length > 0)
  }, [recordCandidates, deleteRecords])

  useEffect(() => {
    onPageRender()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ComplianceContext.Provider
      value={{
        personProfile,
        taskType,
        recordCandidates,
        addRecordCandidate,
        removeRecordCandidate,
        deleteRecords,
        addDeleteRecord,
        hasPendingModifications,
        resetStates,
        resetCounter
      }}>
      {children}
    </ComplianceContext.Provider>
  )
}
