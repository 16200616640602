import { FlexBox, LoadingState } from '@components'
import { Box } from '@mui/material'
import { AxiosError } from 'axios'

import { DialogSaveConfirmation } from './components/DialogSaveConfirmation/DialogSaveConfirmation'
import { DialogSaveFeedback } from './components/DialogSaveFeedback/DialogSaveFeedback'
import { MainContent } from './components/MainContent/MainContent'
import { Sidebar } from './components/Sidebar/Sidebar'

interface IComplianceView {
  createResponse: {
    error?: AxiosError | null
    isRequesting?: boolean
    success?: boolean
  }
  deleteResponse: {
    error?: AxiosError | null
    isRequesting?: boolean
    success?: boolean
  }
  handleClickCompleteTask: () => void
  handleClickSaveTask: () => void
  handleCloseConfirmation: () => void
  handleCloseSaveFeedback: () => void
  handleConfirmSave: () => void
  openSaveConfirmation: boolean
  openSaveFeedback: boolean
  sendingCompleteTask: boolean
}

export const ComplianceView = (props: IComplianceView) => {
  const {
    createResponse,
    deleteResponse,
    handleClickCompleteTask,
    handleClickSaveTask,
    handleCloseConfirmation,
    handleCloseSaveFeedback,
    handleConfirmSave,
    openSaveConfirmation,
    openSaveFeedback,
    sendingCompleteTask
  } = props

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          width: '100vw',
          gridTemplateColumns: '360px 1fr',
          bgcolor: '#fafafa'
        }}>
        <Box height="calc(100vh - 3rem)" overflow="auto">
          <Sidebar
            handleClickCompleteTask={handleClickCompleteTask}
            handleClickSaveTask={handleClickSaveTask}
          />
        </Box>
        <Box height="calc(100vh - 3rem)" overflow="auto">
          {sendingCompleteTask ? (
            <FlexBox pt="8rem">
              <LoadingState message={'Finalizando tarefa...'} />
            </FlexBox>
          ) : (
            <MainContent />
          )}
        </Box>
      </Box>
      {/* ---------- Dialogs ---------- */}
      <DialogSaveConfirmation
        open={openSaveConfirmation}
        handleClose={handleCloseConfirmation}
        handleConfirm={handleConfirmSave}
      />
      <DialogSaveFeedback
        open={openSaveFeedback}
        createResponse={createResponse}
        deleteResponse={deleteResponse}
        handleClose={handleCloseSaveFeedback}
      />
    </>
  )
}
