import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { Map } from './components/map/MapContainer'

export const SegmentationReviewView = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  if (id === undefined) {
    navigate('/bender/tasks')
  }

  return (
    <>
      <Map geoJsonId={id ? id : ''} closeMap={() => navigate('/bender/tasks')} />
    </>
  )
}
