import 'react-datepicker/dist/react-datepicker.css'
import { criminalRecordTypeCatalog } from '@/pages/bender/Compliance/ComplianceCriminal/helpers/criminalRecordTypeCatalog'
import { environmentalRecordTypeCatalog } from '@/pages/bender/Compliance/ComplianceEnvironmental/helpers/environmentalRecordTypeCatalog'
import { fiscalRecordTypeCatalog } from '@/pages/bender/Compliance/ComplianceFiscal/helpers/fiscalRecordTypeCatalog'
import { laborRecordTypeCatalog } from '@/pages/bender/Compliance/ComplianceLabor/helpers/laborRecordTypeCatalog'
import { COMPLIANCE_TASK_TYPE } from '@/pages/bender/Compliance/enums/ComplianceTaskType'
import {
  ComplianceContext,
  documentStatusCatalog,
  IRecordCandidate,
  recordTypeCatalog
} from '@/pages/bender/Compliance/providers/ComplianceContext'
import { SelectChangeEvent } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { FileObject } from 'react-mui-dropzone'
import { useParams } from 'react-router-dom'

import { CreateRecordFormView } from './CreateRecordFormView'

export const CreateRecordForm = () => {
  const { taskType } = useParams() as { taskType: COMPLIANCE_TASK_TYPE }

  const { addRecordCandidate } = useContext(ComplianceContext)

  const [complianceRecord, setComplianceRecord] = useState<Partial<IRecordCandidate>>({})
  const [submitDisabled, setSubmitDisabled] = useState(true)

  const recordTypeOptions = recordTypeCatalog[taskType]
  const documentStatusOptions = documentStatusCatalog[taskType]

  const handleSelectRecordType = (event: SelectChangeEvent) => {
    setComplianceRecord(complianceRecord => ({
      ...complianceRecord,
      recordType: event.target.value as IRecordCandidate['recordType']
    }))
  }

  const handleSelectDocumentStatus = (event: SelectChangeEvent) => {
    setComplianceRecord(complianceRecord => ({
      ...complianceRecord,
      documentStatus: event.target.value as IRecordCandidate['documentStatus']
    }))
  }

  const handleSelectDocumentExpirationDate = (date: Date) => {
    setComplianceRecord(complianceRecord => ({
      ...complianceRecord,
      documentExpirationDate: date
    }))
  }

  const handleAddFile = (newFile: FileObject) => {
    setComplianceRecord(complianceRecord => ({
      ...complianceRecord,
      file: newFile
    }))
  }

  const handleOpenNewTab = () => {
    if (taskType === COMPLIANCE_TASK_TYPE.criminal)
      complianceRecord.recordType &&
        window.open(criminalRecordTypeCatalog[complianceRecord.recordType].urlCheck, '_blank')
    else if (taskType === COMPLIANCE_TASK_TYPE.environmental)
      complianceRecord.recordType &&
        window.open(environmentalRecordTypeCatalog[complianceRecord.recordType].urlCheck, '_blank')
    else if (taskType === COMPLIANCE_TASK_TYPE.fiscal)
      complianceRecord.recordType &&
        window.open(fiscalRecordTypeCatalog[complianceRecord.recordType].urlCheck, '_blank')
    else if (taskType === COMPLIANCE_TASK_TYPE.labor)
      complianceRecord.recordType &&
        window.open(laborRecordTypeCatalog[complianceRecord.recordType].urlCheck, '_blank')
  }

  const clearForm = () => {
    setComplianceRecord({})
  }

  const handleSubmit = () => {
    addRecordCandidate(complianceRecord as IRecordCandidate)
    clearForm()
  }

  useEffect(() => {
    setSubmitDisabled(
      Object.keys(complianceRecord).length < 4 || // num of form fields
        Object.values(complianceRecord).some(value => value == null)
    )
  }, [complianceRecord])

  return (
    <CreateRecordFormView
      handleSelectRecordType={handleSelectRecordType}
      handleSelectDocumentStatus={handleSelectDocumentStatus}
      handleSelectDocumentExpirationDate={handleSelectDocumentExpirationDate}
      handleAddFile={handleAddFile}
      handleOpenNewTab={handleOpenNewTab}
      handleSubmit={handleSubmit}
      recordTypeOptions={recordTypeOptions}
      documentStatusOptions={documentStatusOptions}
      complianceRecord={complianceRecord}
      submitDisabled={submitDisabled}
    />
  )
}
