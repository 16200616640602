/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as turf from '@turf/turf'
import * as React from 'react'

import { AreasListView } from './AreasListView'

interface IAreasList {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geoJson: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setGeoJson: React.Dispatch<React.SetStateAction<any>>
  highlightedFeatId: number | null
  setHighlightedFeatId: React.Dispatch<React.SetStateAction<number | null>>
  editingIndex: number | null
  setEditingIndex: React.Dispatch<React.SetStateAction<number | null>>
  saveEditingArea: () => void
  cancelEditing: () => void
  removeArea: (featId: turf.helpers.Id | undefined) => void
}

export default function AreasList({
  geoJson,
  setGeoJson,
  highlightedFeatId,
  setHighlightedFeatId: setHighlightedFeatId,
  editingIndex,
  setEditingIndex,
  saveEditingArea,
  cancelEditing,
  removeArea
}: IAreasList) {
  const handleListItemClick = (id: number) => {
    setHighlightedFeatId(id)
  }

  const selectedRef = React.useRef<HTMLElement>(null)

  React.useEffect(() => {
    if (selectedRef.current)
      selectedRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      })
  }, [highlightedFeatId])

  const handleSwitchChange = (index: number) => {
    const tempGeoJsonFeatures = geoJson.features
    const isProductive = tempGeoJsonFeatures[index].properties.productive === 1
    tempGeoJsonFeatures[index].properties.productive = isProductive ? 0 : 1
    setGeoJson({
      ...geoJson,
      features: tempGeoJsonFeatures
    })
  }

  return (
    <AreasListView
      cancelEditing={cancelEditing}
      editingIndex={editingIndex}
      geoJson={geoJson}
      handleListItemClick={handleListItemClick}
      handleSwitchChange={handleSwitchChange}
      highlightedFeatId={highlightedFeatId}
      saveEditingArea={saveEditingArea}
      selectedRef={selectedRef}
      setEditingIndex={setEditingIndex}
      removeArea={removeArea}
    />
  )
}
