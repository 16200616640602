import { openConfirm } from '@components'
import { RootState, useAppSelector } from '@redux'
import { BenderApi, BENDER_TASK_STATUS } from '@services'
import { useSnackbar } from 'notistack'
import { useContext, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ComplianceView } from './ComplianceView'
import { useCreateRecordsBackend } from './hooks/useCreateRecordsBackend'
import { useDeleteRecordsBackend } from './hooks/useDeleteRecordsBackend'
import { ComplianceContext } from './providers/ComplianceContext'

export const Compliance = () => {
  const { taskId } = useParams()

  const userInfo = useAppSelector((state: RootState) => state.authenticator.user.info)
  const { taskType, recordCandidates, deleteRecords, resetStates } = useContext(ComplianceContext)
  const metricsEnterPageTimestamp = useRef(new Date())

  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const { createRecordsBackend, ...createResponse } = useCreateRecordsBackend(taskType)
  const { deleteRecordsBackend, ...deleteResponse } = useDeleteRecordsBackend(taskType)

  const [openSaveConfirmation, setOpenSaveConfirmation] = useState(false)
  const [openSaveFeedback, setOpenSaveFeedback] = useState(false)
  const [sendingCompleteTask, setSendingCompleteTask] = useState(false)

  const handleClickSaveTask = () => {
    setOpenSaveConfirmation(true)
  }

  const handleCloseConfirmation = () => {
    setOpenSaveConfirmation(false)
  }

  const handleConfirmSave = () => {
    setOpenSaveConfirmation(false)
    saveRecordsBackend()
    setOpenSaveFeedback(true)
  }

  const saveRecordsBackend = () => {
    createRecordsBackend(recordCandidates)
    deleteRecordsBackend(deleteRecords)
  }

  const handleCloseSaveFeedback = () => {
    resetStates()
    setOpenSaveFeedback(false)
  }

  const handleClickCompleteTask = async () => {
    if (
      await openConfirm(
        'Após finalizada, a tarefa será concluída e removida da lista de revisão. Você deseja finalizar a tarefa?',
        { title: 'Finalizar Tarefa' }
      )
    ) {
      setSendingCompleteTask(true)
      completeTask()
    }
  }

  const completeTask = () => {
    BenderApi.saveTasks(taskId ?? '', {
      completedBy: userInfo.email,
      id: taskId ?? '',
      status: BENDER_TASK_STATUS.SUCCEEDED,
      startedAt: metricsEnterPageTimestamp.current
    })
      .then(() => {
        setSendingCompleteTask(false)
        enqueueSnackbar('Tarefa finalizada com sucesso!', { variant: 'success' })
        navigate(-1)
      })
      .catch(err => {
        alert('Algo deu errado, tente novamente')
        console.error(err)
      })
  }

  return (
    <ComplianceView
      createResponse={createResponse}
      deleteResponse={deleteResponse}
      handleClickSaveTask={handleClickSaveTask}
      handleClickCompleteTask={handleClickCompleteTask}
      handleCloseSaveFeedback={handleCloseSaveFeedback}
      handleConfirmSave={handleConfirmSave}
      openSaveConfirmation={openSaveConfirmation}
      openSaveFeedback={openSaveFeedback}
      handleCloseConfirmation={handleCloseConfirmation}
      sendingCompleteTask={sendingCompleteTask}
    />
  )
}
