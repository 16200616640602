import { Delete, Edit } from '@mui/icons-material'
import { colors, FormControlLabel, Link, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import { makeStyles } from '@mui/styles'
import * as turf from '@turf/turf'

import { CustomSwitch } from './CustomSwitch'

const useStyles = makeStyles(() => ({
  saveButton: {
    padding: '5px 10px',
    border: 'none',
    backgroundColor: '#808080',
    color: 'white',
    borderRadius: '5px',
    fontWeight: 'bold',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

export type TAreasListView = {
  geoJson: turf.FeatureCollection
  highlightedFeatId: number | null
  handleListItemClick: (id: number) => void
  selectedRef: React.RefObject<HTMLElement>
  editingIndex: number | null
  saveEditingArea: () => void
  cancelEditing: () => void
  setEditingIndex: React.Dispatch<React.SetStateAction<number | null>>
  handleSwitchChange: (index: number) => void
  removeArea: (featId: turf.helpers.Id | undefined) => void
}

export const AreasListView = ({
  geoJson,
  highlightedFeatId,
  handleListItemClick,
  selectedRef,
  editingIndex,
  saveEditingArea,
  cancelEditing,
  setEditingIndex,
  handleSwitchChange,
  removeArea
}: TAreasListView) => {
  const styles = useStyles()
  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <List
        component="nav"
        //style={{ maxHeight: '100%', overflow: 'auto'}}
      >
        {geoJson &&
          geoJson.features.map((feature, index) => {
            let isProductive = feature?.properties?.productive === 1
            return (
              <ListItemButton
                key={feature.id}
                selected={highlightedFeatId == feature.id}
                sx={{
                  borderBlock: `1px solid ${
                    highlightedFeatId == feature.id ? colors.amber[500] : 'transparent'
                  }`
                }}
                onClick={() => handleListItemClick(+(feature.id || 0))}>
                <Box
                  ref={highlightedFeatId == feature.id ? selectedRef : undefined}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5rem',
                    alignItems: 'flex-end'
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '0.25rem',
                      color: colors.grey[600],
                      width: '100%'
                    }}>
                    <Typography color={colors.grey[900]} flexGrow={1}>
                      {`Talhão ${feature.id}`}
                    </Typography>
                    {editingIndex === index ? (
                      <div style={{ display: 'flex' }}>
                        <button
                          className={styles.saveButton}
                          onClick={() => {
                            if (confirm('Deseja salvar as mudanças feitas?')) saveEditingArea()
                          }}>
                          Salvar
                        </button>
                        <button
                          className={styles.saveButton}
                          style={{ marginLeft: '5px' }}
                          onClick={() => {
                            if (confirm('Deseja cancelar as mudanças feitas?')) cancelEditing()
                          }}>
                          Cancelar
                        </button>
                      </div>
                    ) : (
                      <>
                        <Edit onClick={() => setEditingIndex(index)} />

                        <Delete
                          onClick={() => {
                            if (confirm('Deseja remover a área selecionada?'))
                              removeArea(feature.id)
                          }}
                        />
                      </>
                    )}
                  </Box>
                  <FormControlLabel
                    control={
                      <CustomSwitch
                        checked={isProductive}
                        onChange={() => {
                          isProductive = !isProductive
                          handleSwitchChange(index)
                        }}
                        sx={{ m: '0.5rem', mr: '1rem' }}
                      />
                    }
                    labelPlacement="start"
                    label={isProductive ? 'Produtivo' : 'Não produtivo'}
                    sx={{
                      width: 'fit-content',
                      '& .MuiTypography-root': {
                        color: isProductive ? colors.blue[500] : colors.deepOrange[500],
                        fontSize: '0.825rem'
                      }
                    }}
                  />
                  <Link
                    href={
                      'https://plataforma.alerta.mapbiomas.org/mapa#?p=' +
                      JSON.stringify(feature.geometry)
                    }
                    underline="always"
                    target="_blank"
                    rel="noreferrer">
                    MAPBIOMAS
                  </Link>
                </Box>
              </ListItemButton>
            )
          })}
      </List>
    </Box>
  )
}
