// import { TSelectedRegion } from '@/services/RadarApi'
import { Close } from '@mui/icons-material'
import {
  Dialog,
  DialogTitle,
  Typography,
  Box,
  IconButton,
  DialogContent,
  CircularProgress,
  colors
} from '@mui/material'
import { useState } from 'react'

import { CustomSwitch } from '../../../AreasList/CustomSwitch'

interface IAreasModal {
  open: boolean
  setOpen: (value: boolean) => void
  areasIds: string[]
  submitMultipleSelection: (selectedIds: string[], isProductive: boolean) => Promise<void>
  exitMultipleSelection: () => void
  // onSubmit: (selectedRegion: TSelectedRegion) => void
}

export const AreasModal = ({
  open,
  setOpen,
  areasIds,
  submitMultipleSelection,
  exitMultipleSelection
}: IAreasModal) => {
  const [isProductive, setIsProductive] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const onClose = () => setOpen(false)

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle display="flex" gap="2rem" alignItems="center">
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0px 18px',
            gap: '10px',
            height: 'fit-content'
          }}>
          <Box />
          <Typography
            variant="h2"
            sx={{
              fontSize: '1.75rem',
              color: '#111315'
            }}>
            Editar talhões
          </Typography>
          <IconButton sx={{ width: '1em', height: '1em' }} onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '15px 0px 0px',
          gap: '16px',
          width: '709px',
          minHeight: isLoading ? '250px' : '200px',
          height: 'fit-content'
        }}>
        <Box
          sx={{
            display: 'flex',
            width: '200px',
            alignItems: 'center'
          }}>
          <CustomSwitch
            checked={isProductive}
            disabled={isLoading}
            onChange={() => {
              setIsProductive(e => !e)
            }}
            sx={{ m: '0.5rem', mr: '1rem' }}
          />

          <Typography
            sx={{
              color: isProductive ? colors.blue[500] : colors.deepOrange[500]
            }}>
            {isProductive ? 'Produtivos' : 'Não produtivos'}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '0px',
            gap: '8px',
            width: '100%',
            height: 'fit-content'
          }}>
          <Typography variant="subtitle1" sx={{ color: '#495057' }}>
            Ao editar os talhões eles serão salvos e não poderão ser editados novamente
          </Typography>
          <Typography variant="h4" sx={{ color: '#495057' }}>
            {areasIds.length > 1
              ? `Você confirma a edição dos ${areasIds.length} talhões selecionados?`
              : 'Você confirma a edição do talhão selecionado?'}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isLoading ? 'column' : 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0px',
            gap: '16px'
          }}>
          {!isLoading ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '14px 18px',
                  gap: '10px',
                  height: '47px',
                  background: '#2B8A3E',
                  boxShadow:
                    '0px 4px 4px rgba(0, 0, 0, 0.25), inset 50px 50px 4px rgba(0, 0, 0, 0.1)',
                  borderRadius: '12px',
                  '&:hover': {
                    cursor: 'pointer'
                  }
                }}
                onClick={() => {
                  setIsLoading(true)
                  submitMultipleSelection(areasIds, isProductive).finally(() => {
                    exitMultipleSelection()
                    setOpen(false)
                    setIsLoading(false)
                  })
                }}>
                <Typography variant="subtitle1" sx={{ color: 'white' }}>
                  Salvar edição
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '14px 18px',
                  gap: '10px',
                  height: '47px',
                  background: '#F03E3E',
                  boxShadow:
                    '0px 4px 4px rgba(0, 0, 0, 0.25), inset 50px 50px 4px rgba(0, 0, 0, 0.1)',
                  borderRadius: '12px',
                  '&:hover': {
                    cursor: 'pointer'
                  }
                }}
                onClick={() => setOpen(false)}>
                <Typography variant="subtitle1" sx={{ color: 'white' }}>
                  Cancelar
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Typography variant="subtitle1">Salvando talhões. Aguarde um momento...</Typography>
              <CircularProgress />
            </>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}
