import { Close, Refresh } from '@mui/icons-material'
import FilterListIcon from '@mui/icons-material/FilterList'
import {
  CircularProgress,
  colors,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput
} from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { visuallyHidden } from '@mui/utils'
import { debounce } from 'lodash'
import moment from 'moment'
import * as React from 'react'

import {
  BenderApi,
  TBenderExecution,
  TExecutionAttributes,
  TSearchExecutions
} from '../../../services/BenderApi'
import { LutienApi } from '../../../services/LutienApi'
import { BENDER_EXECUTION_STATUS } from '../../../services/enums/bender.enum'
import { ExecutionOptionsToolbar } from './components/ExecutionOptions'
import { BasicModal } from './components/ExecutionTreeModal'
import { ExecutionTreeView } from './components/ExecutionTreeView'
import { MultipleAutocomplete } from './components/FilterDropdown'

interface Data {
  index: number
  personProfileId: string
  name: string
  ownerOrganizationId: string
  organizationName: string
  status: BENDER_EXECUTION_STATUS
  updatedAt: string
  id: string
}

function createData(
  index: number,
  personProfileId: string,
  name: string,
  ownerOrganizationId: string,
  organizationName: string,
  status: BENDER_EXECUTION_STATUS,
  updatedAt: string,
  id: string
): Data {
  return {
    index,
    personProfileId,
    name,
    ownerOrganizationId,
    organizationName,
    status,
    updatedAt,
    id
  }
}

type Order = 'asc' | 'desc'

export type TAutocompleteOption = {
  label: string | null | undefined
  value: string
}

interface HeadCell {
  disablePadding: boolean
  id: keyof Data
  label: string
  numeric: boolean
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Cliente'
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status'
  },
  {
    id: 'ownerOrganizationId',
    numeric: false,
    disablePadding: true,
    label: 'Organização'
  },
  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: true,
    label: 'Atualizado em'
  }
]
interface EnhancedTableProps {
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
  order: Order
  orderBy: string
  rowCount: number
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

type TToolbar = {
  handleFilter: () => void
  handleRefresh: () => void
}

const EnhancedTableToolbar = ({ handleFilter, handleRefresh }: TToolbar) => {
  return (
    <Toolbar
      sx={{
        width: '100%',
        gap: '1rem'
      }}>
      <Typography variant="h6" flexGrow={1}>
        Execuções Bender
      </Typography>
      <Tooltip title="Filtrar lista">
        <IconButton onClick={handleFilter}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Atualizar lista">
        <IconButton onClick={handleRefresh}>
          <Refresh />
        </IconButton>
      </Tooltip>
    </Toolbar>
  )
}

type TFilterInput = {
  label: string
  onChange: (value: string) => void
}

const FilterInput = ({ label, onChange }: TFilterInput) => {
  const [value, setValue] = React.useState('')

  const handleChangeValue = (value: string) => {
    setValue(value)
    onChange(value)
  }

  return (
    <FormControl size="small">
      <InputLabel>{label}</InputLabel>
      <OutlinedInput
        value={value}
        onChange={e => handleChangeValue(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            {value && (
              <IconButton edge="end" onClick={() => handleChangeValue('')}>
                <Close fontSize="small" />
              </IconButton>
            )}
          </InputAdornment>
        }
        label={label}
      />
    </FormControl>
  )
}

type TTableFilter = {
  searchValue: string
  status: BENDER_EXECUTION_STATUS[]
  profileIds: string[]
  organizationIds: string[]
}

type TTableFilterSection = {
  hide?: boolean
  addTableFilter: (key: keyof TTableFilter, value: string | string[]) => void
  profileList: TAutocompleteOption[]
  organizationList: TAutocompleteOption[]
}

const uniqueAndNotNull = (
  value: TAutocompleteOption,
  index: number,
  self: TAutocompleteOption[]
) => {
  return index === self.findIndex(t => t.value === value.value) && !!value.value
}

const TableFilterSection = ({
  hide,
  addTableFilter,
  profileList,
  organizationList
}: TTableFilterSection) =>
  hide ? (
    <></>
  ) : (
    <>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          gap: '1rem',
          pb: '2rem',
          pl: '1.5rem'
        }}>
        <FilterInput
          label="Pesquisar execução"
          onChange={value => {
            addTableFilter('searchValue', value)
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          gap: '1rem',
          pb: '2rem',
          pl: '1.5rem'
        }}>
        <MultipleAutocomplete
          label="Cliente"
          handleChange={(
            event: React.SyntheticEvent<Element, Event>,
            selectedValues: TAutocompleteOption[]
          ) => {
            addTableFilter(
              'profileIds',
              selectedValues.map(value => value.value)
            )
          }}
          options={profileList}></MultipleAutocomplete>
        <MultipleAutocomplete
          label="Status"
          handleChange={(
            event: React.SyntheticEvent<Element, Event>,
            selectedValues: TAutocompleteOption[]
          ) => {
            addTableFilter(
              'status',
              selectedValues.map(value => value.value)
            )
          }}
          options={Object.values(BENDER_EXECUTION_STATUS).map(value => {
            return { label: value, value: value }
          })}></MultipleAutocomplete>
        <MultipleAutocomplete
          label="Organização"
          handleChange={(
            event: React.SyntheticEvent<Element, Event>,
            selectedValues: TAutocompleteOption[]
          ) => {
            addTableFilter(
              'organizationIds',
              selectedValues.map(value => value.value)
            )
          }}
          options={organizationList}></MultipleAutocomplete>
      </Box>
    </>
  )

const emptyFilter = {
  searchValue: '',
  status: [],
  profileIds: [],
  organizationIds: []
}

const LIMIT_PER_PAGE = 10

export const BenderExecution = () => {
  const [order, setOrder] = React.useState<Order>('desc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('updatedAt')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(LIMIT_PER_PAGE)
  const [rows, setRows] = React.useState<Data[]>([])
  const [dataResponse, setDataResponse] = React.useState<TBenderExecution[]>([])
  const [selectedStatusTree, setSelectedStatusTree] = React.useState<TBenderExecution[]>([])
  const [selectedRow, setSelectedRow] = React.useState<number>(-1)
  const [isFetchingList, setIsFetchingList] = React.useState(true)
  const [isFetchingTree, setIsFetchingTree] = React.useState(false)
  const [showFilters, setShowFilters] = React.useState(false)
  const [tableFilter, setTableFilter] = React.useState<TTableFilter>(emptyFilter)
  const [showUUID, setShowUUID] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [totalRows, setTotalRows] = React.useState<number>(0)
  const [organizationList, setOrganizationList] = React.useState<TAutocompleteOption[]>([])
  const [profileList, setProfileList] = React.useState<TAutocompleteOption[]>([])
  const [executionAttributes, setExecutionAttributes] = React.useState<TExecutionAttributes[]>([])
  const getOrganizationName = async (orgId: string): Promise<string> => {
    const orgInfo = await LutienApi.getOrganizationInfo(orgId)
    if (!orgInfo) return '#OrganizationName'
    return orgInfo.fantasyName
  }
  const getBenderExecutionsList = async (
    setIsFetching = true,
    rowsPerPageP?: number,
    pageP?: number,
    orderP?: string,
    orderByP?: string,
    tableFilterP?: TTableFilter
  ) => {
    setIsFetchingList(setIsFetching)
    const searchExecutionsBody: TSearchExecutions = {
      filter: {
        inputArrays: {}
      },
      fields: ['details', 'input', 'updatedAt'],
      page: (pageP || 0) + 1,
      limit: rowsPerPageP || LIMIT_PER_PAGE,
      //TODO: fix sortAttribute when it's allowed on backend
      sortAttribute: 'updatedAt',
      sortOrder: (orderP || 'DESC').toUpperCase()
    }
    if (searchExecutionsBody.filter.inputArrays) {
      if (tableFilterP?.searchValue) {
        searchExecutionsBody.filter.searchValue = tableFilterP?.searchValue
      }

      if ((tableFilterP?.profileIds || []).length > 0) {
        searchExecutionsBody.filter.inputArrays.personIds = []
        searchExecutionsBody.filter.inputArrays.personIds = [...(tableFilterP?.profileIds || [])]
      }
      if ((tableFilterP?.organizationIds || []).length > 0) {
        searchExecutionsBody.filter.inputArrays.organizationIds = []
        searchExecutionsBody.filter.inputArrays.organizationIds = [
          ...(tableFilterP?.organizationIds || [])
        ]
      }
      if ((tableFilterP?.status || []).length > 0) {
        searchExecutionsBody.filter.inputArrays.status = []
        searchExecutionsBody.filter.inputArrays.status = [...(tableFilterP?.status || [])]
      }
    }

    const res = await BenderApi.searchExecutions(searchExecutionsBody)

    if (res) {
      setTotalRows(res.total)
      return res?.executions
    }
    return []
  }

  const getBenderExecutionsStatusTree = React.useCallback(
    async (id: string, setIsFetching = true) => {
      setIsFetchingTree(setIsFetching)
      const res = await BenderApi.searchExecutions({
        fields: ['statusTree'],
        filter: { id }
      })
      setIsFetchingTree(false)
      return res.executions
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRow]
  )

  const refreshData = (
    setIsFetching = true,
    rowsPerPageP?: number,
    pageP?: number,
    orderP?: string,
    orderByP?: string,
    tableFilterP?: TTableFilter
  ) => {
    getBenderExecutionsList(
      setIsFetching,
      rowsPerPageP,
      pageP,
      orderP,
      orderByP,
      tableFilterP
    ).then(async data => {
      if (!data.length) {
        setRows([])
        setIsFetchingList(false)
        return
      }
      setDataResponse(data)
      setRows(
        await Promise.all(
          data?.map(async (profile, index) => {
            const organizationName =
              profile.input.personInfo.ownerOrganizationName ??
              (await getOrganizationName(profile.input.personInfo.ownerOrganizationId))
            return createData(
              index,
              profile.input.personInfo.id,
              profile.input.personInfo.name,
              profile.input.personInfo.ownerOrganizationId,
              organizationName,
              profile.details.status,
              profile.updatedAt,
              profile.id
            )
          })
        )
      )
    })
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceExecutions = React.useCallback(
    debounce(
      (
        rowsPerPageP?: number,
        pageP?: number,
        orderP?: string,
        orderByP?: string,
        tableFilterP?: TTableFilter
      ) => {
        refreshData(true, rowsPerPageP, pageP, orderP, orderByP, tableFilterP)
      },
      600
    ),
    []
  )

  const MINUTE_MS = 60000

  React.useEffect(() => {
    debounceExecutions(rowsPerPage, page, order, orderBy, tableFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page, order, orderBy, tableFilter])
  React.useEffect(() => {
    const interval = setInterval(() => {
      refreshData(false)
      getExecutionAttributes()
      if (selectedRow !== -1) {
        const fetchData = async () => {
          const response = await getBenderExecutionsStatusTree(dataResponse[selectedRow].id, false)
          response && setSelectedStatusTree(response)
        }
        fetchData()
      }
    }, MINUTE_MS)

    return () => clearInterval(interval)
  })

  React.useEffect(() => {
    if (rows.length) {
      setIsFetchingList(false)
    }
  }, [rows])

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleClick = async (event: React.MouseEvent<unknown>, index: number) => {
    setSelectedRow(index)
    if (!open) handleOpen()
    const response = await getBenderExecutionsStatusTree(dataResponse[index].id)
    response && setSelectedStatusTree(response)
  }

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const addTableFilter = (key: keyof TTableFilter, value: string | string[]) => {
    const newTableFilter = { ...tableFilter, [key]: value }
    setPage(0)
    setTableFilter(newTableFilter)
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalRows) : 0

  const ChildComp: React.FC = () => (
    <Box>
      <Divider flexItem sx={{ marginBlock: '2rem' }}>
        Árvore de execução
      </Divider>
      <ExecutionTreeView
        data={selectedStatusTree}
        labelIdentifier={dataResponse[selectedRow]?.input?.personInfo?.name}
        isLoading={isFetchingTree}
      />
    </Box>
  )

  const returnOrganizationsList = async () => {
    const distinctExecutionsOrgIds: string[] = executionAttributes
      .reduce((ids: string[], attributes) => {
        if (!attributes.organization.organizationName && attributes.organization.organizationId)
          ids.push(attributes.organization.organizationId)
        return ids
      }, [])
      .filter((value, index, self) => self.indexOf(value) === index)
    const distinctExecutionsOrgNames = Object.create(null)

    await Promise.all(
      distinctExecutionsOrgIds.map(async id => {
        const organizationName = await getOrganizationName(id).catch(err => console.error(err))
        distinctExecutionsOrgNames[id] = organizationName || id
      })
    )
    const returnValue = executionAttributes
      .map(attributes => {
        const autocompleteOption: TAutocompleteOption = {
          label: attributes.organization.organizationName,
          value: attributes.organization.organizationId
        }
        if (!autocompleteOption.label) {
          autocompleteOption.label = distinctExecutionsOrgNames[autocompleteOption.value]
        }
        return autocompleteOption
      })
      .filter(uniqueAndNotNull)
    setOrganizationList(returnValue)
  }

  const returnProfileList = async () => {
    const returnValue = executionAttributes
      .map(attributes => {
        const autocompleteOption: TAutocompleteOption = {
          label: attributes.personProfile.personProfileName,
          value: attributes.personProfile.personProfileId
        }
        return autocompleteOption
      })
      .filter(uniqueAndNotNull)
    setProfileList(returnValue)
  }

  const getExecutionAttributes = async () => {
    const benderExecutionAttributes = await BenderApi.getExecutionAttributes()
    setExecutionAttributes(benderExecutionAttributes)
  }

  React.useEffect(() => {
    getExecutionAttributes()
  }, [])

  React.useEffect(() => {
    returnOrganizationsList()
    returnProfileList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executionAttributes])

  return (
    rows && (
      <Box
        sx={{
          width: 'min(calc(100% - 4rem), 1440px)',
          m: '0 auto',
          p: '2rem'
        }}>
        <BasicModal open={open} ExecutionTree={ChildComp} onClose={handleClose}></BasicModal>
        <Paper
          sx={{
            width: '100%',
            mb: '1rem',
            p: '1rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <EnhancedTableToolbar
            handleFilter={() => setShowFilters(v => !v)}
            handleRefresh={refreshData}
          />
          <TableFilterSection
            hide={showFilters}
            addTableFilter={addTableFilter}
            profileList={profileList}
            organizationList={organizationList}
          />
          <ExecutionOptionsToolbar
            handleOptions={() => setShowUUID(v => !v)}></ExecutionOptionsToolbar>
          {isFetchingList ? (
            <Box
              sx={{
                width: '100%',
                height: '150px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <CircularProgress />
            </Box>
          ) : (
            <TableContainer>
              <Table sx={{ minWidth: 750 }} size="small">
                <EnhancedTableHead
                  numSelected={0}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={totalRows}
                />
                <TableBody>
                  {rows.map(row => {
                    return (
                      <TableRow
                        hover
                        sx={{ '&:hover': { cursor: 'pointer' } }}
                        role="checkbox"
                        tabIndex={-1}
                        key={row.index}
                        selected={selectedRow === row.index}>
                        <TableCell component="th" scope="row" padding="none" align="left">
                          {row.name}
                          <Box
                            sx={{
                              fontSize: '0.75rem',
                              color: colors.grey[700]
                            }}>
                            {showUUID ? row.personProfileId : null}
                          </Box>
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none" align="left">
                          {row.status}
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none" align="left">
                          {row.organizationName}
                          <Box
                            sx={{
                              fontSize: '0.75rem',
                              color: colors.grey[700]
                            }}>
                            {showUUID ? row.ownerOrganizationId : null}
                          </Box>
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none" align="left">
                          {moment(row.updatedAt).format('DD/MM/YYYY')}
                          <Box
                            sx={{
                              fontSize: '0.75rem',
                              color: colors.grey[700]
                            }}>
                            {moment(row.updatedAt).format('HH:mm:ss')}
                          </Box>
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none" align="left">
                          <Link
                            href={
                              'https://us-east-1.console.aws.amazon.com/states/home?region=us-east-1#/v2/executions/details/' +
                              row.id
                            }
                            underline="always"
                            target="_blank"
                            rel="noreferrer">
                            AWS
                          </Link>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 33 * emptyRows
                      }}>
                      <TableCell colSpan={7} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            labelRowsPerPage="Linhas por página"
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    )
  )
}
