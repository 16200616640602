export const fiscalRecordTypeCatalog: Record<
  string,
  { stateShortName: string | null; stateLongName: string | null; urlCheck: string }
> = {
  'CND BA': {
    stateLongName: 'Bahia',
    stateShortName: 'BA',
    urlCheck: 'https://servicos.sefaz.ba.gov.br/sistemas/DSCRE/Modulos/Publico/EmissaoCertidao.aspx'
  },
  'CND Federal': {
    stateLongName: null,
    stateShortName: null,
    urlCheck: 'https://solucoes.receita.fazenda.gov.br/Servicos/certidaointernet/PF/Emitir'
  },
  'CND GO': {
    stateLongName: 'Goiás',
    stateShortName: 'GO',
    urlCheck: 'https://www.sefaz.go.gov.br/Certidao/Emissao/default.asp'
  },
  'CND MG': {
    stateLongName: 'Minas Gerais',
    stateShortName: 'MG',
    urlCheck: 'https://www2.fazenda.mg.gov.br/sol/ctrl/SOL/CDT/SERVICO_829?ACAO=INICIAR'
  },
  'CND MS': {
    stateLongName: 'Mato Grosso do Sul',
    stateShortName: 'MS',
    urlCheck: 'https://servicos.efazenda.ms.gov.br/pndfis/Home/Emissao'
  },
  'CND MT': {
    stateLongName: 'Mato Grosso',
    stateShortName: 'MT',
    urlCheck: 'https://www.sefaz.mt.gov.br/cnd/certidao/servlet/ServletRotd?origem=60'
  },
  'CND PR': {
    stateLongName: 'Paraná',
    stateShortName: 'PR',
    urlCheck: 'http://www.cdw.fazenda.pr.gov.br/cdw/emissao/certidaoAutomatica'
  },
  'CND PA': {
    stateLongName: 'Pará',
    stateShortName: 'PA',
    urlCheck: 'https://app.sefa.pa.gov.br/emissao-certidao/template.action'
  },
  'CND TO': {
    stateLongName: 'Tocantins',
    stateShortName: 'TO',
    urlCheck: 'http://apps.sefaz.to.gov.br/cnd/servlet/hecwbcnd01'
  },
  'CND SP': {
    stateLongName: 'São Paulo',
    stateShortName: 'SP',
    urlCheck:
      'https://www10.fazenda.sp.gov.br/CertidaoNegativaDeb/Pages/EmissaoCertidaoNegativa.aspx'
  },
  'CND RS': {
    stateLongName: 'Rio Grande do Sul',
    stateShortName: 'RS',
    urlCheck: 'https://www.sefaz.rs.gov.br/sat/CertidaoSitFiscalSolic.aspx'
  },
  'CND PI': {
    stateLongName: 'Piauí',
    stateShortName: 'PI',
    urlCheck: 'https://webas.sefaz.pi.gov.br/certidaonft-web/index.xhtml'
  },
  'CND MA': {
    stateLongName: 'Maranhão',
    stateShortName: 'MA',
    urlCheck:
      'http://sistemas.sefaz.ma.gov.br/certidoes/jsp/emissaoCertidaoNegativa/emissaoCertidaoNegativa.jsf'
  },
  'CND RO': {
    stateLongName: 'Rondônia',
    stateShortName: 'RO',
    urlCheck: 'https://portalcontribuinte.sefin.ro.gov.br/Publico/certidaoNegativa.jsp'
  },
  'CND SC': {
    stateLongName: 'Santa Catarina',
    stateShortName: 'SC',
    urlCheck: 'https://sat.sef.sc.gov.br/tax.NET/Sat.CtaCte.Web/SolicitacaoCnd.aspx'
  },
  'CND ES': {
    stateLongName: 'Espírito Santo',
    stateShortName: 'ES',
    urlCheck: 'https://s2-internet.sefaz.es.gov.br/certidao/cnd'
  },
  'CND RR': {
    stateLongName: 'Roraima',
    stateShortName: 'RR',
    urlCheck: 'https://www.sefaz.rr.gov.br/empresa/certidao-negativa-de-debitos'
  },
  'CND AC': {
    stateLongName: 'Acre',
    stateShortName: 'AC',
    urlCheck: 'http://sefaznet.ac.gov.br/sefazonline/servlet/wemcnd'
  },
  'CND RJ': {
    stateLongName: 'Rio de Janeiro',
    stateShortName: 'RJ',
    urlCheck: 'https://www4.fazenda.rj.gov.br/certidao-fiscal-web/emitirCertidao.jsf'
  },
  'CND RN': {
    stateLongName: 'Rio Grande do Norte',
    stateShortName: 'RN',
    urlCheck: 'https://uvt.set.rn.gov.br/#/services/certidao-negativa/emitir'
  },
  'CND DF': {
    stateLongName: 'Distrito Federal',
    stateShortName: 'DF',
    urlCheck: 'https://ww1.receita.fazenda.df.gov.br/cidadao/certidoes/Certidao'
  },
  'CND AM': {
    stateLongName: 'Amazonas',
    stateShortName: 'AM',
    urlCheck:
      'http://sistemas.sefaz.am.gov.br/GAE/mnt/dividaAtiva/certidaoNegativa/emitirCertidaoNegativaNaoContPortal.do'
  }
}
