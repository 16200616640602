import { CircularProgress } from '@material-ui/core'
import { AccessTime } from '@mui/icons-material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import TreeItem, { treeItemClasses, TreeItemProps } from '@mui/lab/TreeItem'
import TreeView from '@mui/lab/TreeView'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { v4 as uuidv4 } from 'uuid'

import { TBenderExecution } from '../../../../services/BenderApi'

declare module 'react' {
  interface CSSProperties {
    '--tree-view-color'?: string
    '--tree-view-bg-color'?: string
  }
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string
  status: string
  labelText: string
}

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: 'var(--tree-view-color)',
  [`& .${treeItemClasses.content}`]: {
    color: 'var(--tree-view-color)',
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: 'var(--tree-view-color)'
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'inherit',
      color: 'inherit'
    }
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: '2rem',
    borderLeft: '1px dashed #616161',
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: '2rem'
    }
  }
}))

function StyledTreeItem(props: StyledTreeItemProps) {
  const { bgColor, status, labelText, ...other } = props

  let LabelIcon
  let color = '#616161'

  switch (status) {
    case 'success':
    case 'SUCCEEDED':
    case 'stopped':
      LabelIcon = CheckIcon
      color = '#388E3C'
      break
    case 'RUNNING':
      LabelIcon = AccessTime
      color = '#303F9F'
      break
    case 'in-progress':
    case 'pending':
      LabelIcon = AccessTime
      color = '#FFA000'
      break
    case 'error':
    case 'FAILED':
    case 'ABORTED':
    case 'TIMED_OUT':
      LabelIcon = CloseIcon
      color = '#D32F2F'
      break
  }

  return (
    <StyledTreeItemRoot
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
          <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
          <Typography variant="body1" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
            {labelText}
          </Typography>
          <Typography variant="body2" color="inherit">
            {status}
          </Typography>
        </Box>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor
      }}
      {...other}
    />
  )
}

export function ExecutionTreeView({
  data,
  labelIdentifier,
  isLoading
}: {
  data: TBenderExecution[]
  labelIdentifier: string
  isLoading: boolean
}) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const buildTreeItem = (data: any) => {
    const elements: JSX.Element[] = []

    if (!data || typeof data !== 'object') return

    Object.keys(data).map(label => {
      if (data[label]?.execution_details?.status) {
        elements.push(
          <StyledTreeItem
            nodeId={uuidv4()}
            labelText={label}
            status={data[label]?.execution_details?.status}>
            {buildTreeItem(data[label])}
          </StyledTreeItem>
        )
      } else {
        const element = buildTreeItem(data[label])
        element && elements.push(...element)
      }
    })

    return elements
  }

  return !data ? (
    <></>
  ) : isLoading ? (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
  ) : (
    <TreeView
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
      sx={{
        width: 'fit-content',
        minWidth: 'min(512px, 70vw)'
      }}>
      <StyledTreeItem nodeId={uuidv4()} labelText={'Execução'} status={labelIdentifier}>
        {buildTreeItem(data)}
      </StyledTreeItem>
    </TreeView>
  )
}
