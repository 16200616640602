import { CopyAll } from '@mui/icons-material'
import { Box, Button, Autocomplete, TextField } from '@mui/material'
import React, { useState, Dispatch, SetStateAction, useEffect, useRef } from 'react'
import ResizePanel from 'react-resize-panel-ts'

import { THubDetectedSeason } from '../../services/HubApi'
import { TCrop } from '../../services/LutienApi'
import { UnidentifiedCrop } from '../../shared/constants/unidentifiedCrop'
import { getDiffInDays } from '../../shared/date'
import { AreasChart, CHART_MODE, TArea, TData } from '../AreasChart/AreasChartContainer'
import { AREA_ACTIONS } from '../AreasChart/enums'
import { AreasTable } from '../AreasTable/AreasTable'
import { TTableArea, TTableAreaInfo } from '../AreasTable/AreasTableView'
import { SeasonsChartLegend } from './components/SeasonsChartLegend'
import { StatusCheck } from './components/StatusCheck'

interface IAreasChartTable {
  ndviData: TData[]
  tableAreas: TTableArea[]
  selectedLandFieldId: string | undefined
  setOpenShareModal: Dispatch<SetStateAction<boolean>>
  setTableAreas: Dispatch<SetStateAction<TTableArea[]>>
  cropsCatalog: TCrop[]
  detectedSeasons: THubDetectedSeason[]
  cultivationAreas: {
    id: string
    name: string
  }[]
  setCultivationAreas: Dispatch<
    SetStateAction<
      {
        id: string
        name: string
      }[]
    >
  >
  onFinishClick?: () => Promise<boolean | undefined>
  isEditable?: boolean
  height?: string
  isLoading?: boolean
}

export const AreasChartTable = ({
  ndviData,
  tableAreas,
  selectedLandFieldId,
  setOpenShareModal,
  setTableAreas,
  cropsCatalog,
  detectedSeasons,
  cultivationAreas,
  setCultivationAreas,
  onFinishClick,
  isEditable,
  height,
  isLoading
}: IAreasChartTable) => {
  const [mode, setMode] = useState(CHART_MODE.VISUALIZATION)
  const [selectedTableAreas, setSelectedTableAreas] = useState<TTableArea[]>([])
  const [tableAreaIdToScroll, setTableAreaIdToScroll] = useState<number>(5)
  const [reducedCropsCatalog, setReducedCropsCatalog] = useState<TCrop[]>([])

  const rowRefToScroll = useRef<HTMLTableRowElement>(null)

  const isDataInitalized = useRef(false)

  const positiveStatus = '#5AEE5A'
  const negativeStatus = '#FA4444'

  /* -------------------- Several Functions -------------------- */

  function fillCreatedAreasWithBasicInfos(newAreas: TTableArea[]): TTableArea[] {
    return newAreas.map(newArea => {
      if (newArea.infos.length > 0) return newArea
      return {
        ...newArea,
        landFieldId: selectedLandFieldId || '',
        xMax: {
          ...newArea.xMax,
          isEditable: true
        },
        xMin: {
          ...newArea.xMin,
          isEditable: true
        },
        color: UnidentifiedCrop.cropColor,
        infos: [
          {
            label: 'Cultura',
            value: UnidentifiedCrop.cropName,
            isEditable: true,
            renderComponent: renderTableComponent
          },
          {
            label: 'Duração',
            value: getDiffInDays(newArea.xMax.value, newArea.xMin.value),
            renderComponent: renderTableComponent
          },
          {
            label: 'Status',
            value: '',
            renderComponent: renderTableComponent
          }
        ]
      }
    })
  }

  function assertCropPeriodIsInAccordance(
    startDate: Date,
    endDate: Date,
    cropName: string
  ): boolean {
    const statusValidation = cropsCatalog.find(crop => crop.cropName === cropName)?.statusValidation

    if (!statusValidation) return false
    const plantingMonth = startDate.getMonth() + 1 // The +1 is necessary because getMonth() starts at 0 and API's month starts at 1
    const harvestMonth = endDate.getMonth() + 1

    if (statusValidation.maxDays && getDiffInDays(endDate, startDate) > statusValidation.maxDays)
      return false
    if (
      plantingMonth >= statusValidation.plantingMonthsInterval.start &&
      plantingMonth <= statusValidation.plantingMonthsInterval.end &&
      harvestMonth >= statusValidation.harvestMonthsInterval.start &&
      harvestMonth <= statusValidation.harvestMonthsInterval.end
    )
      return true
    return false
  }

  function calculateAreasInfo(newAreas: TTableArea[]) {
    newAreas = newAreas.map((newArea, index) => {
      // Map the crop by the area color, because the chart area does not have the crop attribute
      const cropName = cropsCatalog.find(crop => crop.cropColor === newArea.color)?.cropName || ''

      newArea.id = index + 1
      newArea.xMin.label = 'Plantio'
      newArea.xMax.label = 'Colheita'
      newArea.infos = newArea.infos.map(info => {
        if (info.label === 'Cultura')
          return {
            ...info,
            value: cropName
          }
        if (info.label === 'Duração')
          return {
            ...info,
            value: getDiffInDays(newArea.xMax.value, newArea.xMin.value)
          }
        if (info.label === 'Status')
          return {
            ...info,
            value: assertCropPeriodIsInAccordance(
              newArea.xMin.value,
              newArea.xMax.value,
              cropName
            ) ? (
              <StatusCheck color={positiveStatus}></StatusCheck>
            ) : (
              <StatusCheck color={negativeStatus}></StatusCheck>
            )
          }
        return info
      })

      return newArea
    })
    return newAreas
  }

  function updateAreas(newAreas: TTableArea[]) {
    if (newAreas.length > tableAreas.length) {
      newAreas = fillCreatedAreasWithBasicInfos(newAreas)
    } else {
      handleChangeSelectedAreas(AREA_ACTIONS.DESELECT_ALL)
    }

    newAreas = calculateAreasInfo(newAreas)

    setTableAreas([...newAreas])
  }

  function handleChangeSelectedAreas(action: AREA_ACTIONS, area?: TArea) {
    const selectedArea = tableAreas.find(seasonArea => seasonArea.id === area?.id)

    switch (action) {
      case AREA_ACTIONS.SELECT_ALL:
        setSelectedTableAreas(
          tableAreas.filter(({ landFieldId: areaId }) => areaId === selectedLandFieldId)
        )
        break
      case AREA_ACTIONS.DESELECT_ALL:
        setSelectedTableAreas([])
        break
      case AREA_ACTIONS.SELECT:
        if (!area) return
        setTableAreaIdToScroll(area.id)
        if (selectedArea) setSelectedTableAreas(value => [...value, selectedArea])
        break
      case AREA_ACTIONS.DESELECT:
        if (!area) return
        setSelectedTableAreas(value => value.filter(v => v.id !== area.id))
    }
  }

  /*
   * TODO:
   * This function is passed to AreasTable and is reused between differents columns.
   * This got messed because tries to make it generic, but each column has a different behavior.
   */
  function renderTableComponent(
    area: TTableArea,
    info: TTableAreaInfo,
    editionMode: boolean
  ): JSX.Element {
    const onCropChange = (cropName: string) => {
      const newInfos = area.infos.map(currInfo => {
        if (currInfo.label === 'Cultura')
          return {
            ...currInfo,
            value: cropName
          }
        if (currInfo.label === 'Status') {
          return {
            ...currInfo,
            value: assertCropPeriodIsInAccordance(area.xMin.value, area.xMax.value, cropName) ? (
              <StatusCheck color={positiveStatus}></StatusCheck>
            ) : (
              <StatusCheck color={negativeStatus}></StatusCheck>
            )
          }
        }
        return currInfo
      })

      const newColor = cropsCatalog?.find(crop => crop.cropName === cropName)?.cropColor as string

      setTableAreas(prevAreas => {
        const newAreas = prevAreas.map(prevArea => {
          if (prevArea.id === area.id)
            return {
              ...prevArea,
              color: newColor,
              infos: newInfos
            }
          return prevArea
        })

        const currAreas = newAreas.filter(newArea => newArea.landFieldId === area.landFieldId)

        const newReducedCropNames = Array.from(
          new Set(
            currAreas.map(area => {
              const cropInfo = area.infos.find(info => info.label === 'Cultura')
              return cropInfo && cropInfo.value
            })
          )
        )

        const newReducedCrops = cropsCatalog.filter(crop =>
          newReducedCropNames.includes(crop.cropName)
        )

        setReducedCropsCatalog(newReducedCrops)
        return newAreas
      })
    }

    // TODO: The conditional will return the form only for the crop column. The logic can be simplified
    if (
      editionMode &&
      info.isEditable &&
      typeof info.value === 'string' &&
      info.label === 'Cultura'
      //reducedCropsCatalog.find((crop) => crop.cropName === info.value) --unnecessary?
    )
      return (
        <Autocomplete
          defaultValue={info.value}
          options={cropsCatalog.map(crop => crop.cropName)}
          value={info.value}
          onChange={(_, value) => {
            onCropChange(value || 'Não identificado')
          }}
          renderInput={params => <TextField {...params} label="Cultura" />}
        />
      )
    return <>{info.value}</>
  }

  /* -------------------- UseEffects -------------------- */

  useEffect(() => {
    onStatesLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropsCatalog, detectedSeasons, selectedLandFieldId])

  async function onStatesLoad() {
    if (!cropsCatalog || !detectedSeasons || !selectedLandFieldId || isDataInitalized.current)
      return
    isDataInitalized.current = true
    if (reducedCropsCatalog.length > 0) {
      setReducedCropsCatalog(
        Array.from(
          new Set(
            tableAreas
              .filter(area => area.landFieldId === selectedLandFieldId)
              .map(area => cropsCatalog.find(crop => crop.cropColor === area.color) as TCrop)
          )
        )
      )

      return
    }

    const landFieldAreas = detectedSeasons.filter(
      ({ area_id: landFieldId }) => landFieldId === selectedLandFieldId
    )

    setReducedCropsCatalog(
      Array.from(
        new Set(
          landFieldAreas.map(
            newArea =>
              cropsCatalog.find(crop => crop.cropId === newArea.crops_id) || UnidentifiedCrop
          )
        )
      )
    )

    detectedSeasons.forEach(detectedSeason => {
      if (cultivationAreas.filter(a => a.id === (detectedSeason.area_id as string)).length === 0) {
        setCultivationAreas(old => [
          ...old,
          { id: detectedSeason.area_id, name: detectedSeason.area_name }
        ])
      }
    })

    updateAreas(
      detectedSeasons.map((detectedSeason, index) => {
        const currCrop =
          cropsCatalog.find(crop => crop.cropName === detectedSeason.crop_name) || UnidentifiedCrop

        return {
          id: index + 1,
          landFieldId: detectedSeason.area_id,
          xMin: {
            label: 'Plantio',
            value: new Date(
              detectedSeason.start_date.length !== 10
                ? detectedSeason.start_date
                : detectedSeason.start_date.split('-').reverse().join('-')
            ),
            isEditable: true
          },
          xMax: {
            label: 'Colheita',
            value: new Date(
              detectedSeason.end_date.length !== 10
                ? detectedSeason.end_date
                : detectedSeason.end_date.split('-').reverse().join('-')
            ),
            isEditable: true
          },
          color: currCrop.cropColor,
          infos: [
            {
              label: 'Cultura',
              value: currCrop.cropName || '-',
              isEditable: true,
              renderComponent: renderTableComponent
            },
            {
              label: 'Duração',
              value: '',
              renderComponent: renderTableComponent
            },
            {
              label: 'Status',
              value: assertCropPeriodIsInAccordance(
                new Date(
                  detectedSeason.start_date.length !== 10
                    ? detectedSeason.start_date
                    : detectedSeason.start_date.split('-').reverse().join('-')
                ),
                new Date(
                  detectedSeason.end_date.length !== 10
                    ? detectedSeason.end_date
                    : detectedSeason.end_date.split('-').reverse().join('-')
                ),
                detectedSeason.crop_name
              ) ? (
                <StatusCheck color={positiveStatus}></StatusCheck>
              ) : (
                <StatusCheck color={negativeStatus}></StatusCheck>
              ),
              renderComponent: renderTableComponent
            }
          ]
        }
      })
    )
  }

  useEffect(() => {
    if (!rowRefToScroll.current) return
    rowRefToScroll.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start'
    })
  }, [tableAreaIdToScroll])

  return (
    <Box
      sx={{
        width: 'calc(100% - 330px)',
        height: height ? height : '100vh',
        display: 'flex',
        flexDirection: 'column'
      }}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <ResizePanel
        direction="s"
        handleClass="customHandle"
        borderClass="customResizeBorderRow"
        style={{
          minHeight: '120px',
          height: '50vh',
          width: '100%'
        }}>
        <AreasChart
          data={ndviData}
          areas={tableAreas.filter(({ landFieldId: areaId }) => areaId === selectedLandFieldId)}
          setAreas={updatedChartAreas => {
            // The chart area does not carry table infos. So, it needs to be filled
            const updatedAreas = updatedChartAreas.map(updatedChartArea => {
              if (updatedChartArea.id === -1)
                // The new created area
                return {
                  ...updatedChartArea,
                  landFieldId: selectedLandFieldId,
                  infos: []
                } as TTableArea

              const respectiveArea = tableAreas.find(area => area.id === updatedChartArea.id)
              return {
                ...updatedChartArea,
                landFieldId: respectiveArea?.landFieldId || '',
                infos: respectiveArea?.infos || []
              } as TTableArea
            })

            updateAreas([
              ...updatedAreas,
              /*
               * TODO: the chart returns the area of current selected cultivation area
               * but areas carry the area of all cultivation areas of property.
               * Doing a filter every time to replicate the data is a waste of processing.
               */
              ...tableAreas.filter(
                ({ landFieldId: cultAreaId }) => cultAreaId !== selectedLandFieldId
              ) // ???
            ])
          }}
          selectedAreas={selectedTableAreas}
          setSelectedAreas={handleChangeSelectedAreas}
          mode={mode}
          setMode={setMode}
          onFinishClick={onFinishClick}
          legendComponent={<SeasonsChartLegend crops={reducedCropsCatalog} />}
          title="Índice de Vegetação por Diferença Normalizada (NDVI)"
          headerComponent={
            <Button
              variant="discreet"
              onClick={() => setOpenShareModal(true)}
              sx={{
                display: mode === CHART_MODE.VISUALIZATION ? 'flex' : 'none'
              }}>
              <CopyAll fontSize="inherit" />
              Replicar
            </Button>
          }
          isEditable={isEditable}
          isLoading={isLoading}
        />
      </ResizePanel>
      <Box
        sx={{
          flexGrow: 1,
          width: '100%',
          height: 0,
          minHeight: '10px',
          overflowY: 'scroll'
        }}>
        <AreasTable
          areas={tableAreas.filter(({ landFieldId: areaId }) => areaId === selectedLandFieldId)}
          setAreas={(newAreas: TTableArea[]) =>
            updateAreas([
              ...newAreas,
              /*
               * TODO: the chart returns the area of current selected cultivation area
               * but areas carry the area of all cultivation areas of property.
               * Doing a filter every time to replicate the data is a waste of processing.
               */
              ...tableAreas.filter(
                ({ landFieldId: cultAreaId }) => cultAreaId !== selectedLandFieldId
              )
            ])
          }
          selectedAreas={selectedTableAreas}
          setSelectedAreas={handleChangeSelectedAreas}
          mode={mode}
          areaIdToScroll={tableAreaIdToScroll}
          rowRefToScroll={rowRefToScroll}
          dateDomain={
            ndviData.length
              ? {
                  min: new Date(ndviData[0].x),
                  max: new Date(ndviData[ndviData.length - 1].x)
                }
              : undefined
          }
        />
      </Box>
    </Box>
  )
}
