import { BenderApi, TBenderTask } from '@services'
import { useEffect, useState } from 'react'

export const useBenderTask = (taskId?: string) => {
  const [personProfileInfo, setPersonProfileInfo] = useState<TBenderTask['input']['PersonInfo']>()
  const [executionArn, setExecutionArn] = useState<string>()

  const bootstrap = async () => {
    const { tasks } = await BenderApi.searchTasks({
      filter: { id: taskId },
      fields: ['input'],
      limit: 1
    })
    setPersonProfileInfo(tasks[0].input.PersonInfo)
    setExecutionArn(tasks[0].input.Execution?.ExecutionArn)
  }

  useEffect(() => {
    bootstrap()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { personProfileInfo, executionArn }
}
