import { HubApi, THubDetectedSeason } from '@services'
import { useEffect, useState } from 'react'

export const useFetchDetectedSeasons = (propertyId?: string) => {
  const [detectedSeasons, setDetectedSeasons] = useState<THubDetectedSeason[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const bootstrap = async () => {
    if (!propertyId) return
    try {
      const response = await HubApi.getDetectedSeasons(propertyId)
      setDetectedSeasons(response)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error(error)
    }
  }

  useEffect(() => {
    bootstrap()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { detectedSeasons, isLoading }
}
