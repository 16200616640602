/* eslint-disable @typescript-eslint/ban-ts-comment */
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { colors } from '@mui/material'
import booleanIntersects from '@turf/boolean-intersects'
import { FeatureCollection } from '@turf/helpers'
import * as turf from '@turf/turf'
import React, { useEffect, useRef, useState } from 'react'
import { MapRef } from 'react-map-gl'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'

import { TMapLegend } from '../../../components/EditAreasMap/MapLegend'
import { GisApi } from '../../../services/GisApi'
import { RadarApi, TTasksRequest } from '../../../services/RadarApi'
import { SegmentationReviewView } from './SegmentationReviewView'
import { MODAL_OPTIONS, getModalOptions } from './components/ConfirmEditingModal/option.enum'

export const SegmentationReview = () => {
  const [geoJson, setGeoJson] = useState<FeatureCollection | undefined | null>(null)
  const [intersections, setIntersections] = useState<FeatureCollection>({
    features: [],
    type: 'FeatureCollection'
  })
  const [intersectionsVisibility, setIntersectionsVisibility] = useState(true)
  const [editingIndex, setEditingIndex] = useState<number | null>(null)
  const [highlightedFeatId, setHighlitedFeatId] = useState<string | null>(null)
  const [drawRef, setDrawRef] = useState<MapboxDraw | null>(null)
  const [loading, setLoading] = useState(true)
  const [message, setMessage] = useState({
    title: '',
    message: ''
  })
  const [currentTask, setCurrentTask] = useState<TTasksRequest | null>(null)
  const [outterFeatures, setOutterFeatures] = useState<turf.Feature[]>([])

  const { id } = useParams()

  const [editedGeoJson, setEditedGeoJson] = useState<FeatureCollection | undefined | null>(null)
  const [confirmEditingModalOptions, setConfirmEditingModalOptions] = useState<{
    type: MODAL_OPTIONS
    subtitle: string
    ConfirmMessageTitle?: string
    ConfirmMessageBody?: string
    legendValues: TMapLegend
  } | null>(null)
  const [modalGeoJson, setModalGeoJson] = useState<FeatureCollection | undefined | null>(null)
  const [cuttedAreasIds, setCuttedAreasIds] = useState<number[]>([])
  const [deletedAreasIds, setDeletedAreasIds] = useState<number[]>([])

  const mapRef = useRef<MapRef>(null)

  const navigate = useNavigate()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [multipleSelectionMode, setMultipleSelectionMode] = useState(false)

  function onDoneButtonClick() {
    //sidebar confirm button
    setIsModalOpen(true)
  }

  useEffect(() => {
    if (id)
      RadarApi.getTask(id)
        .then(res => setCurrentTask(res))
        .catch(() => setCurrentTask(null))
  }, [id])

  const legendValues = [
    {
      label: 'Área em edição',
      color: '#FFE600',
      dashed: true
    },
    {
      label: 'Produtivo',
      color: '#0099FF',
      dashed: false
    },
    {
      label: 'Não produtivo',
      color: '#C92A2A',
      dashed: false
    },
    {
      label: 'Validado',
      color: colors.grey[500],
      dashed: false
    },
    {
      label: 'Interseção',
      color: '#6C1891',
      dashed: false
    }
  ]

  const handleBack = () => {
    setHighlitedFeatId(null)
    navigate(-1)
  }

  const calculateEditingIntersections = (editedFeature: turf.Feature<turf.Geometry>) => {
    if (!geoJson)
      return {
        intersectionsGeometry: [],
        cuttedAreasGeometry: [],
        cuttedAreasIds: [] as number[],
        deletedAreasGeometry: [],
        deletedAreasIds: [] as number[],
        fobiddenIntersection: false
      }
    const intersectionsFeatures: turf.Geometry[] = []
    const intersectedFeatures: turf.Geometry[] = []
    const intersectedFeaturesIds: number[] = []
    const deletedFeatures: turf.Geometry[] = []
    const deletedFeaturesIds: number[] = []
    let forbiddenIntersection = false
    ;[...geoJson.features, ...outterFeatures].forEach((feature, index) => {
      if (editingIndex !== index) {
        const intersection = turf.intersect(
          feature as turf.Feature<turf.Polygon>,
          editedFeature as turf.Feature<turf.Polygon>
        )
        if (intersection && turf.area(intersection) > 1) {
          intersectionsFeatures.push(intersection.geometry)
          const drawedAreaDiff = turf.difference(
            feature.geometry as turf.Polygon,
            intersection.geometry as turf.Polygon
          )
          if (feature?.properties?.checked) forbiddenIntersection = true
          if (drawedAreaDiff) {
            intersectedFeatures.push(drawedAreaDiff.geometry)
            intersectedFeaturesIds.push(index)
          } else {
            deletedFeatures.push(feature.geometry as turf.Polygon)
            deletedFeaturesIds.push(index)
          }
        }
      }
    })
    return {
      intersectionsGeometry: intersectionsFeatures as turf.Geometry[],
      cuttedAreasGeometry: intersectedFeatures as turf.Geometry[],
      cuttedAreasIds: intersectedFeaturesIds,
      deletedAreasGeometry: deletedFeatures as turf.Geometry[],
      deletedAreasIds: deletedFeaturesIds,
      forbiddenIntersection
    }
  }

  const saveEditingArea = () => {
    if (editingIndex !== null && drawRef !== null && geoJson) {
      setEditedGeoJson(null)
      const newGeoJson = {
        ...geoJson,
        features: [...geoJson.features, ...outterFeatures]
      }
      const featCollection = drawRef.getAll()
      const {
        intersectionsGeometry,
        cuttedAreasGeometry,
        cuttedAreasIds,
        deletedAreasGeometry,
        deletedAreasIds,
        forbiddenIntersection
      } = calculateEditingIntersections(featCollection.features[0] as turf.Feature<turf.Geometry>)
      const editionType = forbiddenIntersection
        ? MODAL_OPTIONS.FORBIDDEN_INTERSECTION
        : intersectionsGeometry.length
        ? MODAL_OPTIONS.PERMITTED_INTERSECTION
        : MODAL_OPTIONS.NO_INTERSECTION
      setConfirmEditingModalOptions({
        type: editionType,
        ...getModalOptions(editionType)
      })

      cuttedAreasIds.forEach((featureId, index) => {
        newGeoJson.features[featureId] = {
          ...newGeoJson.features[featureId],
          geometry: cuttedAreasGeometry[index] as turf.Geometry
        }
      })

      newGeoJson.features[editingIndex] = {
        ...newGeoJson.features[editingIndex],
        properties: {
          ...newGeoJson.features[editingIndex].properties,
          checked: 1
        },
        geometry: featCollection.features[0].geometry as turf.Geometry
      }

      const intersectionsFeatures: turf.Feature[] = intersectionsGeometry.map(e => ({
        type: 'Feature',
        properties: {
          stroke: '#FFE600',
          fill: '#6C1891'
        },
        geometry: e
      }))

      const areasFeatures: turf.Feature[] = cuttedAreasGeometry.map(e => ({
        type: 'Feature',
        properties: {
          stroke: 'transparent',
          fill: '#C92A2A'
        },
        geometry: e
      }))

      setModalGeoJson({
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              stroke: '#FFE600',
              fill: '#FFE600'
            },
            geometry: featCollection.features[0].geometry as turf.Geometry
          },
          ...intersectionsFeatures,
          ...areasFeatures
        ]
      })
      setCuttedAreasIds(cuttedAreasIds)
      setDeletedAreasIds(deletedAreasIds)
      setEditedGeoJson({ ...newGeoJson, features: [...newGeoJson.features] })
    }
  }

  const updateFeaturesSequentially = async (
    geoJsonWithAllFeatures: FeatureCollection<
      turf.helpers.Geometry | turf.helpers.GeometryCollection,
      turf.helpers.Properties
    >,
    currentIndex: number,
    arrayOfFeatureIndexes: number[],
    checked?: boolean
  ): Promise<number[]> => {
    const emptyArrayPromise: Promise<number[]> = new Promise(function (myResolve, myReject) {
      myResolve([] as number[])
      myReject([] as number[])
    })

    if (currentIndex < arrayOfFeatureIndexes.length) {
      const id = arrayOfFeatureIndexes[currentIndex]
      const currentFeature = {
        ...geoJsonWithAllFeatures.features[id],
        properties: {
          id: geoJsonWithAllFeatures.features[id].id
        }
      }
      if (currentFeature.id) {
        try {
          // if (turf.area(currentFeature.geometry as turf.Geometry) > 0) {
          await GisApi.saveFeature(
            currentFeature.id.toString(),
            // turf.cleanCoords(currentFeature.geometry as turf.Geometry),
            currentFeature.geometry as turf.Geometry,
            !!geoJsonWithAllFeatures.features[id].properties?.productive,
            checked ?? false
          )
          console.log(`Talhão ${id} salvo com sucesso`)
          return [
            id,
            ...(await updateFeaturesSequentially(
              geoJsonWithAllFeatures,
              currentIndex + 1,
              arrayOfFeatureIndexes,
              checked
            ))
          ]
        } catch (e: any) {
          console.log('erro ao salvar as modificações', e)
          return emptyArrayPromise
        }
      } else {
        return new Promise(function (myResolve, myReject) {
          myResolve([] as number[])
          myReject([] as number[])
        })
      }
    } else {
      return emptyArrayPromise
    }
  }

  const deleteFeaturesSequentially = async (
    geoJsonWithAllFeatures: FeatureCollection<
      turf.helpers.Geometry | turf.helpers.GeometryCollection,
      turf.helpers.Properties
    >,
    currentIndex: number,
    arrayOfFeatureIndexes: number[]
  ): Promise<number[]> => {
    const emptyArrayPromise: Promise<number[]> = new Promise(function (myResolve, myReject) {
      myResolve([] as number[])
      myReject([] as number[])
    })

    if (currentIndex < arrayOfFeatureIndexes.length) {
      const id = arrayOfFeatureIndexes[currentIndex]
      const currentFeature = {
        ...geoJsonWithAllFeatures.features[id],
        properties: {
          id: geoJsonWithAllFeatures.features[id].id
        }
      }
      if (currentFeature.id) {
        try {
          await GisApi.removeArea(currentFeature.id.toString())
          console.log(`Talhão ${id} removido com sucesso, id: ${currentFeature.id}`)
          return [
            id,
            ...(await deleteFeaturesSequentially(
              geoJsonWithAllFeatures,
              currentIndex + 1,
              arrayOfFeatureIndexes
            ))
          ]
        } catch (e: any) {
          console.log('erro ao salvar as deleções', e)
          return emptyArrayPromise
        }
      } else {
        return new Promise(function (myResolve, myReject) {
          myResolve([] as number[])
          myReject([] as number[])
        })
      }
    } else {
      return emptyArrayPromise
    }
  }

  // *** useEffect to clean all checked features, do not uncomment
  // useEffect(()=>{
  //   if(geoJson){
  //     updateFeaturesSequentially(geoJson, 0, geoJson.features.map((f,i)=>i).filter(e => e !== 14)).finally(()=>setLoading(false))
  //   }
  // },[geoJson])

  const confirmSaveEditingArea = () => {
    setLoading(true)
    if (editedGeoJson) {
      updateFeaturesSequentially(editedGeoJson, 0, cuttedAreasIds).then(savedIndexes => {
        if (savedIndexes.length === cuttedAreasIds.length) {
          deleteFeaturesSequentially(editedGeoJson, 0, deletedAreasIds).then(deletedIndexes => {
            if (editingIndex !== null && deletedIndexes.length === deletedAreasIds.length) {
              const currentFeature = editedGeoJson.features[editingIndex]
              if (currentFeature.id)
                GisApi.saveFeature(
                  currentFeature.id.toString(),
                  // turf.cleanCoords(currentFeature.geometry as turf.Geometry),
                  currentFeature.geometry as turf.Geometry,
                  !!editedGeoJson.features[editingIndex].properties?.productive,
                  true
                )
                  .then(() => {
                    console.log('Talhão editado salvo com sucesso')
                    const outterIds = outterFeatures.map(f => f.id?.toString())
                    setGeoJson({
                      ...editedGeoJson,
                      features: editedGeoJson.features.filter((f, i) => {
                        return !deletedIndexes.includes(i) && !outterIds.includes(f.id?.toString())
                      })
                    })
                    cancelEditing()
                    setEditedGeoJson(null)
                  })
                  .catch(e => {
                    const intersections = e.response.data.intersection.features as turf.Feature[]
                    setOutterFeatures(
                      intersections
                        .filter(e => {
                          return (
                            currentTask &&
                            !booleanIntersects(
                              e.geometry as turf.Geometry,
                              currentTask.geometry as turf.Geometry
                            )
                          )
                        })
                        .map(
                          feature =>
                            ({
                              ...feature,
                              id: feature.properties?.id,
                              properties: {
                                checked: feature.properties?.has_been_checked ? 1 : 0,
                                productive: feature.properties?.has_productive_area ? 1 : 0
                              }
                            } as turf.Feature)
                        )
                    )
                    console.log('erro ao salvar area editada', e)
                    rollbackChanges(savedIndexes, deletedIndexes)
                    // cancelEditing();
                  })
                  .finally(() => {
                    // cancelEditing();
                    // setEditedGeoJson(null);
                    setTimeout(() => setLoading(false), 1000)
                  })
            } else {
              rollbackChanges(savedIndexes, deletedIndexes)
              cancelEditing()
            }
          })
        } else {
          rollbackChanges(savedIndexes, [])
          cancelEditing()
        }
      })
    }
  }

  const rollbackChanges = (savedIndexes: number[], deletedIndexes: number[]) => {
    if (geoJson) {
      updateFeaturesSequentially(geoJson, 0, savedIndexes).then(() => {
        if (deletedIndexes.length > 0)
          deletedIndexes.forEach((i, index) => {
            GisApi.createArea(
              geoJson.features[i].geometry as turf.Geometry,
              !!geoJson.features[i].properties?.productive,
              false
            ).then(res => {
              geoJson.features[i].id = res.id
              if (index === deletedIndexes.length - 1) {
                setMessage({
                  title: 'Erro ao salvar',
                  message:
                    'Devido a inconsistências ao salvar as mudanças, as alterações foram desfeitas. Pedimos desculpas pelo ocorrido.'
                })
                // cancelEditing();
                // setEditedGeoJson(null);
                setTimeout(() => setLoading(false), 1000)
              }
            })
          })
        else {
          setMessage({
            title: 'Erro ao salvar',
            message:
              'Devido a inconsistências ao salvar as mudanças, as alterações foram desfeitas. Pedimos desculpas pelo ocorrido.'
          })
          // cancelEditing();
          // setEditedGeoJson(null);
          setTimeout(() => setLoading(false), 1000)
        }
      })
    }
  }

  const cancelEditing = () => {
    if (drawRef && mapRef.current) {
      const index = editingIndex
      drawRef.deleteAll()
      mapRef.current.getMap().removeControl(drawRef)
      setEditingIndex(null)
      setOutterFeatures([])
      setDrawRef(null)
      if (index !== null) calculateIntersections(index)
    }
  }

  const submitMultipleSelection = async (selectedIds: string[], isProductive: boolean) => {
    if (geoJson) {
      const indexes: number[] = []
      const newGeoJson = {
        ...geoJson,
        features: geoJson.features.map((feature, index) => {
          if (!(feature.id && selectedIds.includes(feature.id.toString()))) return feature
          indexes.push(index)
          return {
            ...feature,
            properties: {
              ...feature.properties,
              productive: isProductive ? 1 : 0,
              checked: 1
            }
          }
        })
      }

      const savedIndexes = await updateFeaturesSequentially(newGeoJson, 0, indexes, true)
      if (savedIndexes.length === indexes.length) {
        setGeoJson(newGeoJson)
      } else {
        setMessage({
          title: 'Erro ao salvar',
          message:
            'Alguns talhões não foram salvos com a nova produtividade, pedimos que cheque e refaça a operação.'
        })
        const savedIds = newGeoJson.features
          .filter((e, i) => e.id && savedIndexes.includes(i))
          .map(f => f.id?.toString())
        setGeoJson(e => {
          if (!e) return e
          return {
            ...e,
            features: e?.features.map(feature => {
              if (feature.id && savedIds.includes(feature.id.toString()))
                return {
                  ...feature,
                  properties: {
                    ...feature.properties,
                    productive: isProductive ? 1 : 0,
                    checked: 1
                  }
                }
              return feature
            })
          }
        })
      }
    }
  }

  useEffect(() => {
    if (id !== undefined)
      RadarApi.getAreas(id)
        .then(res => {
          if (res)
            setGeoJson({
              ...res,
              features: res.features.map(
                feature =>
                  ({
                    ...feature,
                    id: feature.properties?.area_id,
                    properties: {
                      checked: feature.properties?.has_been_checked ? 1 : 0,
                      productive: feature.properties?.has_productive_area ? 1 : 0
                    }
                  } as turf.Feature)
              )
            } as turf.FeatureCollection)
          else alert('Nenhum talhão encontrado na região')
        })
        .catch(e => console.log(e))
    else alert('Id de tarefa inválido, tente novamente')

    setTimeout(() => setLoading(false), 3000)
  }, [])

  useEffect(() => {
    window.onbeforeunload = confirmExit
    function confirmExit() {
      if (loading) return 'show warning'
      return null
    }
  }, [loading])

  const isFeatureEditable = (feature: turf.Feature): boolean => {
    return !(feature.properties?.checked == 1)
  }

  const removeArea = (index: number) => {
    setLoading(true)
    if (geoJson?.features) {
      const areaId = geoJson.features[index].id?.toString()
      if (areaId !== undefined) {
        GisApi.removeArea(areaId)
          .then(() => {
            const features: turf.FeatureCollection<
              turf.Geometry | turf.GeometryCollection
            >['features'] = []
            geoJson.features.forEach((feat, i) => {
              if (i !== index) features.push(feat)
            })
            setGeoJson({ ...geoJson, features: features })
            setTimeout(() => setLoading(false), 500)
          })
          .catch(() => {
            alert('Erro na remoção de área, tente novamente!')
            setTimeout(() => setLoading(false), 500)
          })
      } else {
        alert('Erro na remoção de área, tente novamente!')
        setTimeout(() => setLoading(false), 500)
      }
    } else {
      alert('Erro na remoção de área, tente novamente!')
      setTimeout(() => setLoading(false), 500)
    }
  }

  const calculateIntersections = (index: number) => {
    if (!geoJson) return
    const intersectionsFeatures: turf.Feature<turf.Polygon | turf.MultiPolygon>[] = []
    const feature = geoJson.features[index]
    for (let i = 0; i < geoJson.features.length; i++) {
      if (i === index) continue
      const intersection = turf.intersect(
        feature as turf.Feature<turf.Polygon>,
        geoJson.features[i] as turf.Feature<turf.Polygon>
      )
      intersection && turf.area(intersection) >= 0.01 && intersectionsFeatures.push(intersection)
    }
    setIntersections({
      type: 'FeatureCollection',
      features: intersectionsFeatures
    })
  }

  return (
    <SegmentationReviewView
      handleBack={handleBack}
      loading={loading}
      geoJson={geoJson}
      setGeoJson={setGeoJson}
      highlightedFeatId={highlightedFeatId}
      setHighlitedFeatId={setHighlitedFeatId}
      editingIndex={editingIndex}
      setEditingIndex={setEditingIndex}
      cancelEditing={cancelEditing}
      removeArea={removeArea}
      saveEditingArea={saveEditingArea}
      legendValues={legendValues}
      drawRef={drawRef}
      setDrawRef={setDrawRef}
      intersectionsVisibility={intersectionsVisibility}
      setIntersectionsVisibility={setIntersectionsVisibility}
      confirmEditingModalOptions={confirmEditingModalOptions}
      setConfirmEditingModalOptions={setConfirmEditingModalOptions}
      confirmSaveEditingArea={confirmSaveEditingArea}
      modalGeoJson={modalGeoJson}
      intersections={intersections}
      setIntersections={setIntersections}
      mapRef={mapRef}
      isFeatureEditable={isFeatureEditable}
      messageData={{
        ...message,
        clearMessage: () => setMessage({ title: '', message: '' })
      }}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      onDoneButtonClick={onDoneButtonClick}
      outterFeatures={outterFeatures}
      multipleSelectionMode={multipleSelectionMode}
      setMultipleSelectionMode={setMultipleSelectionMode}
      submitMultipleSelection={submitMultipleSelection}
    />
  )
}
