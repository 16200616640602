/* eslint-disable @typescript-eslint/ban-ts-comment */

import MapboxDraw from '@mapbox/mapbox-gl-draw'
import bbox from '@turf/bbox'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import { FeatureCollection, point } from '@turf/helpers'
import * as turf from '@turf/turf'
import React, { useEffect, useState } from 'react'
import { MapRef } from 'react-map-gl'
import WebMercatorViewport from 'viewport-mercator-project'

import { TMapLegend } from '../../../../../components/EditAreasMap/MapLegend'
import { MapView } from './MapView'
import { ConfirmEditingModal } from './components/ConfirmEditingModal'
import { MODAL_OPTIONS } from './components/ConfirmEditingModal/option.enum'
import { AreasModal } from './components/multipleSelectModal'

interface IMap {
  geoJson:
    | FeatureCollection<
        turf.helpers.Geometry | turf.helpers.GeometryCollection,
        turf.helpers.Properties
      >
    | null
    | undefined
  setGeoJson: React.Dispatch<
    React.SetStateAction<
      | FeatureCollection<
          turf.helpers.Geometry | turf.helpers.GeometryCollection,
          turf.helpers.Properties
        >
      | null
      | undefined
    >
  >
  editingIndex: number | null
  drawRef: MapboxDraw | null
  setDrawRef: React.Dispatch<React.SetStateAction<MapboxDraw | null>>
  highlightedFeatId: string | null
  setHighlitedFeatId: React.Dispatch<React.SetStateAction<string | null>>
  intersectionsVisibility: boolean
  setIntersectionsVisibility: React.Dispatch<React.SetStateAction<boolean>>
  confirmEditingModalOptions: {
    type: MODAL_OPTIONS
    subtitle: string
    ConfirmMessageTitle?: string | undefined
    ConfirmMessageBody?: string | undefined
    legendValues: TMapLegend
  } | null
  setConfirmEditingModalOptions: React.Dispatch<
    React.SetStateAction<{
      type: MODAL_OPTIONS
      subtitle: string
      ConfirmMessageTitle?: string | undefined
      ConfirmMessageBody?: string | undefined
      legendValues: TMapLegend
    } | null>
  >
  confirmSaveEditingArea: () => void
  legendValues: TMapLegend
  modalGeoJson:
    | FeatureCollection<
        turf.helpers.Geometry | turf.helpers.GeometryCollection,
        turf.helpers.Properties
      >
    | null
    | undefined
  intersections: FeatureCollection<
    turf.helpers.Geometry | turf.helpers.GeometryCollection,
    turf.helpers.Properties
  >
  setIntersections: React.Dispatch<
    React.SetStateAction<
      FeatureCollection<
        turf.helpers.Geometry | turf.helpers.GeometryCollection,
        turf.helpers.Properties
      >
    >
  >
  mapRef: React.RefObject<MapRef>
  isFeatureEditable: (feature: turf.Feature) => boolean
  outterFeatures: turf.Feature[]
  multipleSelectionMode: boolean
  setMultipleSelectionMode: React.Dispatch<React.SetStateAction<boolean>>
  submitMultipleSelection: (selectedIds: string[], isProductive: boolean) => Promise<void>
}

export const Map = ({
  geoJson,
  setGeoJson,
  editingIndex,
  drawRef,
  setDrawRef,
  highlightedFeatId,
  setHighlitedFeatId,
  intersectionsVisibility,
  setIntersectionsVisibility,
  confirmEditingModalOptions,
  setConfirmEditingModalOptions,
  confirmSaveEditingArea,
  legendValues,
  modalGeoJson,
  intersections,
  setIntersections,
  isFeatureEditable,
  mapRef,
  outterFeatures,
  multipleSelectionMode,
  setMultipleSelectionMode,
  submitMultipleSelection
}: IMap) => {
  const [triggerCalculateIntersections, setTriggerCalculateIntersections] = useState(false)
  const [selectedAreasIds, setSelectedAreasIds] = useState<string[]>([])
  const [selectAreasModal, setSelectAreasModal] = useState(false)

  const getBoundsForPoints = (
    polygon: turf.FeatureCollection | turf.Geometry | turf.GeometryCollection
  ) => {
    const cornersLongLat = bbox(polygon)
    const viewport = new WebMercatorViewport({
      width: 800,
      height: 600
    }).fitBounds(
      [
        [cornersLongLat[0], cornersLongLat[1]],
        [cornersLongLat[2], cornersLongLat[3]]
      ],
      { padding: 40 }
    )
    const { longitude, latitude, zoom } = viewport
    return { center: { lon: longitude, lat: latitude }, zoom }
  }

  const calculateDrawIntersections = (draw: turf.Feature) => {
    if (!geoJson) return
    const intersectionsFeatures: turf.Feature<turf.Polygon | turf.MultiPolygon>[] = []
    geoJson.features.forEach((feature, index) => {
      if (index !== editingIndex) {
        const intersection = turf.intersect(
          feature as turf.Feature<turf.Polygon>,
          draw as turf.Feature<turf.Polygon>
        )
        intersection && intersectionsFeatures.push(intersection)
      }
    })
    outterFeatures.forEach((feature, index) => {
      const intersection = turf.intersect(
        feature as turf.Feature<turf.Polygon>,
        draw as turf.Feature<turf.Polygon>
      )
      intersection && turf.area(intersection) >= 0.01 && intersectionsFeatures.push(intersection)
    })
    setIntersections({
      type: 'FeatureCollection',
      features: intersectionsFeatures.filter(e => turf.area(e.geometry) > 0.01)
    })
  }

  const getClickedLayerId = (long: number, lat: number): string | null => {
    const pt = point([long, lat])
    let id: turf.helpers.Id | undefined | null = null
    geoJson?.features?.forEach(feature => {
      if (feature && booleanPointInPolygon(pt, feature.geometry as turf.Polygon)) {
        id = feature.id
      }
    })
    return id
  }

  const handleSwitchChange = (featureId: string) => {
    setGeoJson(value => {
      if (!value) return
      return {
        ...value,
        features: value?.features?.map(feature => {
          if (feature.id === featureId && feature.properties)
            feature.properties.productive = feature?.properties?.productive ? 0 : 1
          return feature
        })
      }
    })
  }

  const handleToggleIntersectionsVisibility = () => {
    setIntersectionsVisibility(value => {
      if (!value && intersections.features.length)
        mapRef.current?.flyTo({
          ...getBoundsForPoints(intersections),
          duration: 400
        })
      return !value
    })
  }

  useEffect(() => {
    if (geoJson) {
      // setViewportOptions(getBoundsForPoints(geoJson));
      mapRef.current?.getMap().flyTo({
        ...getBoundsForPoints(geoJson),
        duration: 400,
        padding: 20
      })
    }
  }, [geoJson, multipleSelectionMode])

  useEffect(() => {
    if (geoJson && highlightedFeatId !== null)
      if (multipleSelectionMode) {
        const selectedFeature = geoJson.features.find(feature => feature.id === highlightedFeatId)
        console.log('oieee', selectedFeature)
        if (selectedFeature && selectedFeature.properties?.checked) {
          alert('Erro: Talhão já validado!')
        } else {
          const temp = selectedAreasIds
          console.log('opa', temp, highlightedFeatId, temp.includes(highlightedFeatId))
          if (temp.includes(highlightedFeatId))
            setSelectedAreasIds(temp.filter(e => e !== highlightedFeatId))
          else setSelectedAreasIds([...temp, highlightedFeatId])
        }
        setHighlitedFeatId(null)
      } else {
        geoJson.features.forEach(feature => {
          if (feature.id == highlightedFeatId)
            mapRef.current?.getMap().flyTo({
              ...getBoundsForPoints(feature.geometry),
              duration: 400,
              padding: 20
            })
        })
      }

    // else {
    //   setViewportOptions(getBoundsForPoints(geoJson));
    // }
  }, [highlightedFeatId, geoJson])

  const draw = new MapboxDraw({
    displayControlsDefault: false,
    controls: {}
  })

  useEffect(() => {
    if (drawRef !== null)
      try {
        calculateDrawIntersections(drawRef.getAll().features[0] as turf.Feature)
      } catch (e) {
        // console.log('error', e)
      }
  }, [outterFeatures, geoJson, triggerCalculateIntersections])

  const removeAreaByFeatureId = (featId: number | string) => {
    if (geoJson?.features) {
      const features: turf.FeatureCollection<turf.Geometry | turf.GeometryCollection>['features'] =
        []
      geoJson.features.forEach(feat => {
        if (feat.id != featId) features.push(feat)
      })
      setGeoJson({ ...geoJson, features: features })
    }
  }

  // useEffect for opening edit area mode
  useEffect(() => {
    const map = mapRef.current
    if (map && geoJson) {
      if (editingIndex !== null && drawRef === null) {
        map.getMap().addControl(draw)
        setDrawRef(draw)
        if (geoJson.features[editingIndex]) {
          draw.set({
            // @ts-ignore
            features: [geoJson.features[editingIndex]],
            type: 'FeatureCollection'
          })
          draw.changeMode(
            // @ts-ignore
            'direct_select',
            {
              featureId: geoJson.features[editingIndex].id
            }
          )
        }
      }
    }
  }, [mapRef.current, editingIndex, geoJson])

  const pressDelete = (event: KeyboardEvent) => {
    if (event.key === 'Delete') {
      drawRef?.trash()
    }
    // else if (
    //   event.key === 'Delete' &&
    //   highlightedFeatId !== null &&
    //   geoJson
    // ) {
    //   if (confirm(`Deseja remover a área selecionada?`)) {
    //     removeAreaByFeatureId(highlightedFeatId);
    //   }
    // }
  }

  useEffect(() => {
    window.addEventListener('keydown', pressDelete)
    return () => {
      window.removeEventListener('keydown', pressDelete)
    }
  }, [highlightedFeatId, geoJson, drawRef])

  const cancelMultipleSelection = () => {
    if (confirm('Deseja cancelar a seleção múltipla?')) {
      setSelectedAreasIds([])
      setHighlitedFeatId(null)
      setMultipleSelectionMode(false)
    }
  }

  const startMultipleSelection = () => {
    setSelectedAreasIds([])
    setHighlitedFeatId(null)
    setMultipleSelectionMode(true)
  }

  const exitMultipleSelection = () => {
    setSelectedAreasIds([])
    setHighlitedFeatId(null)
    setMultipleSelectionMode(false)
  }

  const saveMultipleSelection = () => {
    setSelectAreasModal(true)
  }

  return (
    <>
      {confirmEditingModalOptions !== null && modalGeoJson && (
        <ConfirmEditingModal
          open={true}
          {...confirmEditingModalOptions}
          geoJson={modalGeoJson}
          onClose={() => setConfirmEditingModalOptions(null)}
          onSave={() => {
            setConfirmEditingModalOptions(null)
            confirmSaveEditingArea()
          }}
        />
      )}
      <AreasModal
        open={selectAreasModal}
        setOpen={(value: boolean) => setSelectAreasModal(value)}
        areasIds={selectedAreasIds}
        submitMultipleSelection={submitMultipleSelection}
        exitMultipleSelection={exitMultipleSelection}
      />
      <MapView
        mapRef={mapRef}
        onLoad={() => {
          mapRef.current?.getMap().on('draw.update', () => {
            setTriggerCalculateIntersections(e => !e)
          })
        }}
        onMapClick={props => {
          // eslint-disable-next-line react/prop-types
          props.originalEvent.preventDefault()
          if (editingIndex === null) {
            // eslint-disable-next-line react/prop-types
            const id = getClickedLayerId(props.lngLat.lng, props.lngLat.lat)
            setHighlitedFeatId(id)
          }
        }}
        onMapDblClick={props => {
          // eslint-disable-next-line react/prop-types
          props.originalEvent.preventDefault()
          if (editingIndex === null) {
            // eslint-disable-next-line react/prop-types
            const id = getClickedLayerId(props.lngLat.lng, props.lngLat.lat)
            setHighlitedFeatId(id)
          }
        }}
        editingIndex={editingIndex}
        intersections={intersections}
        intersectionsVisibility={intersectionsVisibility}
        legendValue={legendValues}
        handleToggleIntersectionsVisibility={handleToggleIntersectionsVisibility}
        geoJson={geoJson}
        highlightedFeatId={highlightedFeatId}
        isFeatureEditable={isFeatureEditable}
        // currentTask={currentTask}
        outterFeatures={outterFeatures}
        multipleSelectionMode={multipleSelectionMode}
        startMultipleSelection={startMultipleSelection}
        cancelMultipleSelection={cancelMultipleSelection}
        saveMultipleSelection={saveMultipleSelection}
        selectedAreasIds={selectedAreasIds}
      />
    </>
  )
}
