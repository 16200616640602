/* eslint-disable @typescript-eslint/ban-ts-comment */
import { CheckCircle, Visibility, VisibilityOff } from '@mui/icons-material'
import LoadingButton from '@mui/lab/LoadingButton'
import { Badge, Button, colors, Tooltip, Box } from '@mui/material'
import { FeatureCollection } from '@turf/helpers'
import * as turf from '@turf/turf'
import mapboxgl from 'mapbox-gl'
import { RefObject } from 'react'
import MapGl, { MapRef } from 'react-map-gl'

import { LatLongCopyButton } from '../LatLongCopyButton/LatLongCopyButton'
import { TMapLegend, MapLegend } from './MapLegend'

interface IEditAreasMap {
  mapRef: RefObject<MapRef>
  onMapClick: (props: mapboxgl.MapLayerMouseEvent) => void
  onMapDblClick?: (props: mapboxgl.MapLayerMouseEvent) => void
  editingIndex: number | null
  intersections: FeatureCollection<
    turf.helpers.Geometry | turf.helpers.GeometryCollection,
    turf.helpers.Properties
  >
  intersectionsVisibility: boolean
  isLoadingSubmit?: boolean
  onSubmit?: () => void
  highlightedFeatCoordinates?: turf.helpers.Position | null
  handleToggleIntersectionsVisibility: () => void
  children: JSX.Element
  legend?: TMapLegend
  onDragEnd?: () => void
  onMouseUp?: () => void
  onLoad?: () => void
}

export const EditAreasMap = ({
  mapRef,
  onMapClick,
  onMapDblClick,
  editingIndex,
  intersections,
  intersectionsVisibility,
  isLoadingSubmit,
  onSubmit,
  highlightedFeatCoordinates,
  handleToggleIntersectionsVisibility,
  children,
  legend,
  onDragEnd,
  onMouseUp,
  onLoad
}: IEditAreasMap) => {
  return (
    <Box
      sx={{
        flexGrow: '1',
        minWidth: '50vw',
        maxHeight: 'calc(100vh - 3rem)'
      }}>
      <MapGl
        attributionControl={false}
        ref={mapRef}
        mapStyle="mapbox://styles/mapbox/satellite-v9"
        mapboxAccessToken="pk.eyJ1IjoiY2FybG9zbmV0byIsImEiOiJja293NmJjMXkwMm9xMm9xd2dsb3c5MzVnIn0.afW1LTUwzWdSthdlbJvJrg"
        doubleClickZoom={false}
        onDblClick={onMapDblClick}
        onClick={onMapClick}
        onDragEnd={onDragEnd}
        onMouseUp={onMouseUp}
        onLoad={onLoad}>
        {children}
      </MapGl>
      {onSubmit && (
        <LoadingButton
          loading={isLoadingSubmit}
          variant="contained"
          sx={{
            display: editingIndex ? 'none' : 'flex',
            position: 'fixed',
            right: '0.5rem',
            bottom: '0.5rem',
            gap: '0.5rem',
            bgcolor: '#fff',
            '&:hover': {
              bgcolor: colors.grey[200]
            }
          }}
          onClick={onSubmit}>
          {'Finalizar'}
          <CheckCircle fontSize="small" sx={{ color: colors.green[500] }} />
        </LoadingButton>
      )}
      {legend && <MapLegend legendValues={legend} right />}
      <Box
        sx={{
          position: 'fixed',
          right: '0.5rem',
          top: '3.5rem',
          textAlign: 'right'
        }}>
        <Box
          sx={{
            marginBottom: '0.5rem',
            justifyContent: 'right',
            display: 'flex',
            marginRight: '0.5rem'
          }}>
          {highlightedFeatCoordinates ? (
            <LatLongCopyButton coordinates={highlightedFeatCoordinates} />
          ) : null}
        </Box>

        <Tooltip
          title={intersectionsVisibility ? 'Ocultar interseções' : 'Mostrar interseções'}
          placement="left">
          <Badge
            badgeContent={intersections.features.length}
            color="error"
            sx={{
              '& .MuiBadge-badge': {
                right: 36,
                top: 5,
                padding: '0 4px'
              }
            }}>
            <Button
              variant="contained"
              size="small"
              sx={{
                minWidth: 0,
                padding: '0.5rem',
                bgcolor: '#fff',
                '&:hover': {
                  bgcolor: colors.grey[200]
                }
              }}
              onClick={handleToggleIntersectionsVisibility}>
              {intersectionsVisibility ? (
                <Visibility fontSize="small" />
              ) : (
                <VisibilityOff fontSize="small" />
              )}
            </Button>
          </Badge>
        </Tooltip>
      </Box>
    </Box>
  )
}
