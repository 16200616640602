import axios from 'axios'

import { store, RootState } from '../../redux/store'

const reduxState: RootState = store.getState()

export const axiosBender = axios.create({
  timeout: 20000,
  baseURL: process.env.REACT_APP_URL_BENDER_API,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${reduxState.authenticator.user.token}`
  }
})

// axiosBender.interceptors.response.use(
//   (response) => response,
//   (err) =>
//     console.log(
//       `ERROR: URL DA REQUEST: ${err?.config?.baseURL}${err?.config?.url}, Token: ${err?.config?.headers?.Authorization}`
//     )
// );
