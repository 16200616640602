import { FeatureCollection } from '@turf/helpers'

import { DeepPartial } from '../utils/DeepPartial'
import { axiosBender } from './config/axiosBender.config'
import { BENDER_EXECUTION_STATUS, BENDER_TASK_STATUS, BENDER_TASK_TYPE } from './enums/bender.enum'

export type TBenderExecution = {
  id: string
  details: {
    name: string
    output: string
    status: BENDER_EXECUTION_STATUS
    stopDate: string
    startDate: string
    executionArn: string
    [key: string]: unknown
  }
  input: {
    personInfo: {
      id: string
      name: string
      canonicalProfileId: string
      ownerOrganizationId: string
      brandedOrganizationId: string
      statusOnInternalRevenue: string
      ownerOrganizationName: string
      [key: string]: unknown
    }
  }
  updatedAt: string
}

export type TBenderExecutionsFilter = {
  searchValue?: string
  inputArrays?: {
    status?: string[]
    personIds?: string[]
    organizationIds?: string[]
  }

  id?: string
}

export type TBenderTask = {
  id: string
  taskToken: string
  executionArn: string
  input: Partial<{
    Execution: {
      ExecutionArn: string
    }
    Property: {
      PropertyId: string
      PropertyName: string
    }
    Collectors: {
      BrokenCollectors: string[]
    }
    PersonInfo: {
      PersonId: string
      PersonName: string
      PersonType: 'NATURAL' | 'LEGAL'
      PersonGovRegCode: string
      PersonCanonicalId: string
    }
    Organization: {
      OrganizationId: string
      OrganizationName: string
    }
    GISSegmentationOutput: {
      key: string
      bucket: string
      status: string
    }
  }>
  status: BENDER_TASK_STATUS
  type: BENDER_TASK_TYPE
  completedBy: string
  completionInput: unknown
  completedAt: string | null
  updatedAt: string
  createdAt: string
}

export type TBenderTasksFilter = {
  searchValue?: string
  types?: string[]
  id?: string
  inputArrays?: {
    Status?: string[]
    PersonIds?: string[]
    OrganizationIds?: string[]
    PropertyIds?: string[]
    Type?: string[]
  }
}

export type TSearchExecutions = {
  filter: TBenderExecutionsFilter
  fields: ('details' | 'input' | 'updatedAt' | 'statusTree')[]
  page?: number
  limit?: number
  sortAttribute?: string
  sortOrder?: string
}

export type TSearchTasks = {
  filter: DeepPartial<TBenderTasksFilter>
  fields: ('input' | 'createdAt' | 'updatedAt' | 'status' | 'type')[]
  page?: number
  limit?: number
  sortAttribute?: string
  sortOrder?: string
}

export type TGetGeoJson = {
  id: string
}

export interface CompletionInput {
  [index: string]: unknown
}

export interface CompletionError {
  cause?: string
  error?: string
}
export interface TaskCompletion {
  id: string
  completedBy: string
  status: BENDER_TASK_STATUS
  completionInput?: CompletionInput | CompletionInput[]
  completionError?: CompletionError
  startedAt: Date
}

export type ExecutionsResponse = {
  executions: TBenderExecution[]
  total: number
}

export type TasksResponse = {
  tasks: TBenderTask[]
  total: number
}

export interface TExecutionAttributes {
  organization: {
    organizationId: string
    organizationName: string | null
  }
  personProfile: {
    personProfileId: string
    personProfileName: string
  }
}

export interface TTaskAttributes {
  organization: {
    organizationId: string
    organizationName: string
  }
  personProfile: {
    personProfileId: string
    personProfileName: string
  }
  property: {
    propertyId: string
    propertyName: string
  }
}

const searchExecutions = async (body: TSearchExecutions) => {
  const { data } = await axiosBender.post<ExecutionsResponse>('/executions/search', body)
  return data
}

const getExecutionAttributes = async () => {
  const { data } = await axiosBender.get<TExecutionAttributes[]>('/executions/get-attributes')
  return data
}

const searchTasks = async (body: TSearchTasks) => {
  const { data } = await axiosBender.post<TasksResponse>('/task/search', body)
  return data
}

const searchTaskList = async () => {
  const { data } = await axiosBender.get<TTaskAttributes[]>('/task/get-attributes')
  return data
}

const getTasks = async () => {
  const body = {
    filter: {
      types: ['SEGMENTATION_REVIEW', 'DETECT_SEASON_REVIEW'],
      inputArrays: {
        Status: [BENDER_TASK_STATUS.PENDING]
      }
    }
  }

  const { data } = await axiosBender.post<{
    tasks: TBenderTask[]
    total: number
  }>('/task/search', body)
  return data
}

const getGeoJson = async ({ id }: TGetGeoJson) => {
  const { data } = await axiosBender.get<FeatureCollection>(`/task/segmentation/${id}/geojson`)
  return data
}

const saveTasks = async (taskId: string, body: TaskCompletion) => {
  if (!body.completionInput) body.completionInput = { message: 'accepted' }

  const { data } = await axiosBender.post(`/task/${taskId}/complete`, body)
  return data
}

export const BenderApi = {
  searchExecutions,
  getTasks,
  searchTasks,
  getGeoJson,
  saveTasks,
  searchTaskList,
  getExecutionAttributes
}
