/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-named-as-default-member */
import { Close } from '@mui/icons-material'
import {
  CircularProgress,
  colors,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput
} from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { debounce } from 'lodash'
import moment from 'moment'
import React from 'react'

import { BENDER_TASK_STATUS, BENDER_TASK_TYPE } from '../../../services/enums/bender.enum'
import { StatusDropdown } from './components/StatusDropdown'
import { EnhancedTableHead } from './components/TableHead'
import { EnhancedTableToolbar } from './components/TableToolbar'
import { TaskOptionsToolbar } from './components/TaskOptions'

export interface TaskTableData {
  index: number
  taskId: string
  status: BENDER_TASK_STATUS
  type: BENDER_TASK_TYPE
  createdAt: string
  PersonId: string
  PersonName: string
  PropertyId: string
  PropertyName: string
  OrganizationId: string
  OrganizationName: string
}

export type TReviewTasksView = {
  order: 'asc' | 'desc'
  orderBy: keyof TaskTableData
  isFetchingList: boolean
  handleRequestSort: (event: React.MouseEvent<unknown>, property: keyof TaskTableData) => void
  rows: TaskTableData[]
  page: number
  rowsPerPage: number
  handleClick: (event: React.MouseEvent<unknown>, index: number) => void
  stableSort: <T>(array: readonly T[], comparator: (a: T, b: T) => number) => T[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getComparator: <Key extends keyof any>(
    order: 'asc' | 'desc',
    orderBy: Key
  ) => (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number
  selectedRow: number
  emptyRows: number
  handleChangePage: (event: unknown, newPage: number) => void
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void
  setPage: React.Dispatch<React.SetStateAction<number>>
  totalRows: number
  refreshData: (
    setIsFetching: boolean,
    tableFilter: TTableFilter,
    rowsPerPageP?: number,
    pageP?: number,
    orderP?: string
  ) => void
  profileList: TAutocompleteOption[]
  organizationList: TAutocompleteOption[]
  propertyList: TAutocompleteOption[]
  getTaskList: () => void
}

export type TAutocompleteOption = {
  label: string
  value: string
}

type TFilterInput = {
  label: string
  onChange: (value: string) => void
}

const FilterInput = ({ label, onChange }: TFilterInput) => {
  const [value, setValue] = React.useState('')

  const handleChangeValue = (value: string) => {
    setValue(value)
    onChange(value)
  }

  return (
    <FormControl
      size="small"
      sx={{
        width: 250
      }}>
      <InputLabel>{label}</InputLabel>
      <OutlinedInput
        value={value}
        onChange={e => handleChangeValue(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            {value && (
              <IconButton edge="end" onClick={() => handleChangeValue('')}>
                <Close fontSize="small" />
              </IconButton>
            )}
          </InputAdornment>
        }
        label={label}
      />
    </FormControl>
  )
}

export type TTableFilter = {
  searchValue: string
  status: BENDER_TASK_STATUS[]
  organizationIds: string[]
  profileIds: string[]
  propertyIds: string[]
  type: string[]
}

type TTableFilterSection = {
  hide?: boolean
  addTableFilter: (key: keyof TTableFilter, value: string | string[]) => void
  profileList: TAutocompleteOption[]
  organizationList: TAutocompleteOption[]
  propertyList: TAutocompleteOption[]
}

const TableFilterSection = ({
  hide,
  addTableFilter,
  profileList,
  organizationList,
  propertyList
}: TTableFilterSection) =>
  hide ? (
    <></>
  ) : (
    <>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          gap: '1rem',
          pb: '1rem',
          pl: '1.5rem'
        }}>
        <FilterInput
          label="Pesquisar tarefas"
          onChange={value => {
            addTableFilter('searchValue', value)
          }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          width: '100%',
          gap: '1rem',
          pl: '1.5rem',
          pr: '1.5rem',
          pb: '1rem'
        }}>
        <StatusDropdown
          label="Status"
          handleChange={(
            event: React.SyntheticEvent<Element, Event>,
            value: TAutocompleteOption[]
          ) => {
            addTableFilter(
              'status',
              value.map(value => value.value)
            )
          }}
          options={Object.values(BENDER_TASK_STATUS).map(value => {
            return { label: value, value: value }
          })}
          defaultValue={[
            {
              label: BENDER_TASK_STATUS.PENDING,
              value: BENDER_TASK_STATUS.PENDING
            }
          ]}></StatusDropdown>

        <StatusDropdown
          label="Organização"
          handleChange={(
            event: React.SyntheticEvent<Element, Event>,
            selectedValues: TAutocompleteOption[]
          ) => {
            addTableFilter(
              'organizationIds',
              selectedValues.map(value => value.value)
            )
          }}
          options={organizationList}></StatusDropdown>

        <StatusDropdown
          label="Perfil"
          handleChange={(
            event: React.SyntheticEvent<Element, Event>,
            selectedValues: TAutocompleteOption[]
          ) => {
            addTableFilter(
              'profileIds',
              selectedValues.map(value => value.value)
            )
          }}
          options={profileList}></StatusDropdown>

        <StatusDropdown
          label="Propriedade"
          handleChange={(
            event: React.SyntheticEvent<Element, Event>,
            selectedValues: TAutocompleteOption[]
          ) => {
            addTableFilter(
              'propertyIds',
              selectedValues.map(value => value.value)
            )
          }}
          options={propertyList}></StatusDropdown>
        <StatusDropdown
          label="Tipo"
          handleChange={(
            event: React.SyntheticEvent<Element, Event>,
            selectedValues: TAutocompleteOption[]
          ) => {
            addTableFilter(
              'type',
              selectedValues.map(value => value.value)
            )
          }}
          options={Object.values(BENDER_TASK_TYPE).map(value => {
            return { label: value, value: value }
          })}
          defaultValue={
            [
              // {
              //   label: BENDER_TASK_TYPE.DETECT_SEASON_REVIEW,
              //   value: BENDER_TASK_TYPE.DETECT_SEASON_REVIEW
              // },
              // {
              //   label: BENDER_TASK_TYPE.SEGMENTATION_REVIEW,
              //   value: BENDER_TASK_TYPE.SEGMENTATION_REVIEW
              // },
              // {
              //   label: BENDER_TASK_TYPE.CRIMINAL_COMPLIANCE_REVIEW,
              //   value: BENDER_TASK_TYPE.CRIMINAL_COMPLIANCE_REVIEW
              // },
              // {
              //   label: BENDER_TASK_TYPE.ENVIRONMENTAL_COMPLIANCE_REVIEW,
              //   value: BENDER_TASK_TYPE.ENVIRONMENTAL_COMPLIANCE_REVIEW
              // },
              // {
              //   label: BENDER_TASK_TYPE.FISCAL_COMPLIANCE_REVIEW,
              //   value: BENDER_TASK_TYPE.FISCAL_COMPLIANCE_REVIEW
              // },
              // {
              //   label: BENDER_TASK_TYPE.LABOR_COMPLIANCE_REVIEW,
              //   value: BENDER_TASK_TYPE.LABOR_COMPLIANCE_REVIEW
              // }
            ]
          }></StatusDropdown>
      </Box>
    </>
  )

const emptyFilter: TTableFilter = {
  searchValue: '',
  status: [BENDER_TASK_STATUS.PENDING],
  organizationIds: [],
  profileIds: [],
  propertyIds: [],
  type: [
    // BENDER_TASK_TYPE.SEGMENTATION_REVIEW,
    // BENDER_TASK_TYPE.DETECT_SEASON_REVIEW,
    // BENDER_TASK_TYPE.CRIMINAL_COMPLIANCE_REVIEW,
    // BENDER_TASK_TYPE.ENVIRONMENTAL_COMPLIANCE_REVIEW,
    // BENDER_TASK_TYPE.FISCAL_COMPLIANCE_REVIEW,
    // BENDER_TASK_TYPE.LABOR_COMPLIANCE_REVIEW
  ]
}

export const ReviewTasksView = ({
  order,
  orderBy,
  handleRequestSort,
  rows,
  page,
  rowsPerPage,
  handleClick,
  selectedRow,
  emptyRows,
  handleChangePage,
  handleChangeRowsPerPage,
  setPage,
  isFetchingList,
  totalRows,
  refreshData,
  profileList,
  organizationList,
  propertyList,
  getTaskList
}: TReviewTasksView) => {
  const [hideFilters, setHideFilters] = React.useState(false)
  const [tableFilter, setTableFilter] = React.useState<TTableFilter>(emptyFilter)
  const [showUUID, setShowUUID] = React.useState(false)

  const MINUTE_MS = 60000
  const searchDelayed = React.useCallback(debounce(refreshData, 700), [])

  React.useEffect(() => {
    refreshData(false, tableFilter)
  }, [])

  React.useEffect(() => {
    searchDelayed(true, tableFilter, rowsPerPage, page, order)
  }, [rowsPerPage, page, order, tableFilter])

  React.useEffect(() => {
    const interval = setInterval(() => {
      getTaskList()
      refreshData(true, tableFilter)
    }, MINUTE_MS)

    return () => clearInterval(interval)
  }, [tableFilter])

  const addTableFilter = (key: keyof TTableFilter, value: string | string[]) => {
    const newTableFilter = { ...tableFilter, [key]: value }
    setPage(0)
    setTableFilter(newTableFilter)
  }

  return (
    <Box
      sx={{
        width: '100vw - 4rem',
        maxWidth: 'xl',
        m: '3rem auto',
        p: '2rem'
      }}>
      <Paper
        sx={{
          maxWidth: '100%',
          mb: '1rem',
          p: '1rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <EnhancedTableToolbar handleFilter={() => setHideFilters(v => !v)} />
        <TableFilterSection
          hide={hideFilters}
          addTableFilter={addTableFilter}
          profileList={profileList}
          organizationList={organizationList}
          propertyList={propertyList}
        />

        <TaskOptionsToolbar handleOptions={() => setShowUUID(v => !v)}></TaskOptionsToolbar>
        {isFetchingList ? (
          <Box
            sx={{
              width: '100%',
              height: '150px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer>
            <Table sx={{ minWidth: 700 }} size="small">
              <EnhancedTableHead
                numSelected={0}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={totalRows}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
                {rows.map(row => {
                  const labelId = `enhanced-table-checkbox-${row.index}`

                  return (
                    <TableRow
                      hover
                      sx={{ '&:hover': { cursor: 'pointer' } }}
                      onClick={event => handleClick(event, row.index)}
                      role="checkbox"
                      tabIndex={-1}
                      key={row.index}
                      selected={selectedRow === row.index}>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="normal"
                        align="left">
                        {row.status}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="normal"
                        align="left">
                        {row.OrganizationName}
                        <Box
                          sx={{
                            fontSize: '0.75rem',
                            color: colors.grey[700]
                          }}>
                          {showUUID ? row.OrganizationId : null}
                        </Box>
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="normal"
                        align="left">
                        {row.PersonName}
                        <Box
                          sx={{
                            fontSize: '0.75rem',
                            color: colors.grey[700]
                          }}>
                          {showUUID ? row.PersonId : null}
                        </Box>
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="normal"
                        align="left">
                        {row.PropertyName}
                        <Box
                          sx={{
                            fontSize: '0.75rem',
                            color: colors.grey[700]
                          }}>
                          {showUUID ? row.PropertyId : null}
                        </Box>
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="normal"
                        align="left">
                        {row.type}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="normal"
                        align="left">
                        {moment(row.createdAt).format('DD/MM/YYYY')}
                        <Box
                          sx={{
                            fontSize: '0.75rem',
                            color: colors.grey[700]
                          }}>
                          {moment(row.createdAt).format('HH:mm:ss')}
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 33 * emptyRows
                    }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage="Linhas por página"
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  )
}
