export enum USER_ROLE {
  ROLE_STAFF = 'ROLE_STAFF',
  ROLE_CUSTOMER = 'ROLE_CUSTOMER'
}

export enum USER_GENDER {
  MAN = 'MAN',
  WOMAN = 'WOMAN',
  OTHER = 'OTHER',
  NOT_WANT_INFORM = 'NOT_WANT_INFORM'
}
