import { getFiscalAssessmentLevel } from '@/pages/bender/Compliance/ComplianceFiscal/helpers/getFiscalAssessmentLevel'
import {
  ComplianceContext,
  IDeleteRecord,
  IRecordCandidate,
  recordTypeCatalog
} from '@/pages/bender/Compliance/providers/ComplianceContext'
import { FiscalDebtInquiryDTO } from '@/services/Hub/AssessmentsFiscal'
import { FISCAL_ASSESSMENT_STATUS } from '@/services/Hub/enums/FiscalDebtInquiry.enum'
import { Table, TRowRules, openConfirm } from '@components'
import { Delete, Download } from '@mui/icons-material'
import { Box, Button, IconButton, Typography } from '@mui/material'
import moment from 'moment'
import { useContext, useEffect, useRef, useState } from 'react'

import { fiscalRecordTypeCatalog } from '../helpers/fiscalRecordTypeCatalog'

type IFiscalRecordCandidate = Omit<IRecordCandidate, 'documentStatus'> & {
  documentStatus: FISCAL_ASSESSMENT_STATUS
}

const rowRules: TRowRules = {
  range: {
    label: 'União',
    renderComponent: (value: string) => <Typography>{value}</Typography>
  },
  stateShortName: {
    label: 'UF',
    renderComponent: (value: string | null) => <Typography>{value || ''}</Typography>
  },
  status: {
    label: 'Status',
    renderComponent: (value: string) => <Typography>{value}</Typography>
  },
  expirationDate: {
    label: 'Data de Validade',
    renderComponent: (value: Date) => (
      <Typography>{moment(value).format('DD/MM/YYYY, h:mm a')}</Typography>
    )
  },
  documentUrl: {
    label: 'Certidão',
    renderComponent: (props: { value: string; candidate: boolean }) =>
      props.candidate ? (
        <Typography>{props.value}</Typography>
      ) : (
        <Button variant="text" color="info" onClick={() => window.open(props.value, '__blank')}>
          <Download />
          Download
        </Button>
      )
  },
  delete: {
    label: 'Remover',
    renderComponent: (props: { value: string; handleDelete: () => void }) => (
      <IconButton
        onClick={async () => {
          const res = await openConfirm('Deseja remover o registro?', { title: 'Remover' })
          res && props.handleDelete()
        }}>
        <Delete />
      </IconButton>
    )
  }
}

function createRows(
  fiscalDebtInquiries: FiscalDebtInquiryDTO[],
  handleDelete: (record: IDeleteRecord) => void
) {
  return fiscalDebtInquiries.map(fiscalAssessment => ({
    _candidate: false,
    _id: fiscalAssessment.id,
    range: fiscalAssessment.level,
    stateShortName: fiscalAssessment.stateShortName || null,
    status: fiscalAssessment.status,
    expirationDate: new Date(fiscalAssessment.expirationDate),
    documentUrl: { value: fiscalAssessment.documentUrl, candidate: false },
    delete: {
      value: fiscalAssessment.id,
      handleDelete: () => handleDelete({ id: fiscalAssessment.id })
    }
  }))
}

function createRowsFromCandidates(
  candidates: IFiscalRecordCandidate[],
  handleDelete: (index: number) => void
) {
  return candidates.map(candidate => ({
    _candidate: true,
    _id: candidate?.index?.toString() || '',
    range: getFiscalAssessmentLevel(
      candidate.recordType as typeof recordTypeCatalog['fiscal'][number]
    ),
    stateShortName: fiscalRecordTypeCatalog[candidate.recordType].stateShortName,
    status: candidate.documentStatus,
    expirationDate: new Date(candidate.documentExpirationDate),
    documentUrl: { value: candidate.file.file.name, candidate: true },
    delete: {
      value: candidate.index?.toString() || '',
      handleDelete: () => handleDelete(candidate.index ?? -1)
    }
  }))
}

interface IFiscalTableProps {
  fiscalDebtInquiries: FiscalDebtInquiryDTO[]
}

export const FiscalTable = (props: IFiscalTableProps) => {
  const { fiscalDebtInquiries } = props
  const { recordCandidates, removeRecordCandidate, deleteRecords, addDeleteRecord } =
    useContext(ComplianceContext)
  const rowsFromServer = useRef(createRows(fiscalDebtInquiries, addDeleteRecord))
  const [rows, setRows] = useState(rowsFromServer.current)

  useEffect(() => {
    const recordCandidatesRows = createRowsFromCandidates(
      recordCandidates as IFiscalRecordCandidate[],
      removeRecordCandidate
    )
    setRows(prevRows => [
      ...prevRows.filter(({ _candidate }) => !_candidate),
      ...recordCandidatesRows
    ])
  }, [recordCandidates, removeRecordCandidate])

  useEffect(() => {
    setRows(prevRows => prevRows.filter(prevRow => !deleteRecords.find(v => v.id === prevRow._id)))
  }, [deleteRecords])

  return (
    <Box sx={{ minWidth: '900px', maxWidth: '1440px' }}>
      <Typography variant="h2" mb="1rem">
        Compliance Fiscal
      </Typography>
      <Table rowRules={rowRules} rows={rows} />
    </Box>
  )
}
