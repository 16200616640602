import { IRecordCandidate } from '@/pages/bender/Compliance/providers/ComplianceContext'
import { Dropzone, FlexBox } from '@components'
import { OpenInNew } from '@mui/icons-material'
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material'
import DatePicker from 'react-datepicker'
import { FileObject } from 'react-mui-dropzone'

import { CustomDropzoneContent } from './components/CustomDropzoneContent'

interface ICreateRecordFormViewProps {
  handleSelectRecordType: (event: SelectChangeEvent) => void
  handleSelectDocumentStatus: (event: SelectChangeEvent) => void
  handleSelectDocumentExpirationDate: (date: Date) => void
  handleAddFile: (newFile: FileObject) => void
  handleOpenNewTab: () => void
  handleSubmit: () => void
  recordTypeOptions: Readonly<string[]>
  documentStatusOptions: Readonly<string[]>
  complianceRecord: Partial<IRecordCandidate>
  submitDisabled: boolean
}

export const CreateRecordFormView = (props: ICreateRecordFormViewProps) => {
  const {
    handleSelectRecordType,
    handleSelectDocumentStatus,
    handleSelectDocumentExpirationDate,
    handleAddFile,
    handleOpenNewTab,
    handleSubmit,
    recordTypeOptions,
    documentStatusOptions,
    complianceRecord,
    submitDisabled
  } = props

  return (
    <FlexBox
      vertical
      sx={{
        alignItems: 'flex-start',
        gap: '1rem'
      }}>
      <Typography variant="h5">Criar Registro de Compliance</Typography>

      <FlexBox>
        <FormControl sx={{ width: '100%', flexGrow: 1 }} variant="standard">
          <InputLabel>Tipo de Registro</InputLabel>
          <Select
            value={complianceRecord.recordType || ''}
            label="Tipo de Registro"
            onChange={handleSelectRecordType}>
            {recordTypeOptions.map(item => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <IconButton color="info" disabled={!complianceRecord.recordType} onClick={handleOpenNewTab}>
          <OpenInNew />
        </IconButton>
      </FlexBox>

      <FormControl sx={{ width: '100%' }} variant="standard">
        <InputLabel>Status do Documento</InputLabel>
        <Select
          value={complianceRecord.documentStatus || ''} // empty string is used to indicate not selected value
          label="Status do Documento"
          onChange={handleSelectDocumentStatus}>
          {documentStatusOptions.map(status => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <DatePicker
        dateFormat="dd/MM/yyyy"
        selected={complianceRecord.documentExpirationDate}
        onChange={date => date && handleSelectDocumentExpirationDate(date)}
        customInput={
          <TextField sx={{ width: '100%' }} variant="standard" label="Data de Validade" />
        }
      />

      <Dropzone
        fileObjects={[]}
        filesLimit={1}
        acceptedFiles={['.pdf']}
        content={<CustomDropzoneContent file={complianceRecord.file} />}
        onAdd={newFiles => handleAddFile(newFiles[0])}
        maxFileSize={50e6} /* 50MB */
        showAlerts={false}
      />

      <Button
        disabled={submitDisabled}
        variant="discreet"
        style={{ minWidth: '128px' }}
        onClick={handleSubmit}>
        Adicionar
      </Button>
    </FlexBox>
  )
}
