import axios from 'axios'

// import { store } from '../../redux/store';

export const axiosLutien = axios.create({
  timeout: 20000,
  baseURL: process.env.REACT_APP_URL_LUTIEN_API,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

// axiosLutien.interceptors.request.use(async (req) => {
//   const { authenticator } = store.getState();
//   req.headers = {
//     ...req.headers,
//     Authorization: `Bearer ${authenticator.token}`,
//   };
//   return req;
// });

// axiosLutien.interceptors.response.use(
//   (response) => response,
//   (err) =>
//     console.log(
//       `ERROR: URL DA REQUEST: ${err?.config?.baseURL}${err?.config?.url}, Token: ${err?.config?.headers?.Authorization}`
//     )
// );
