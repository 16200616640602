import { CreateFiscalDebtInquiryDTO } from '@/services/Hub/AssessmentsFiscal'
import { FISCAL_ASSESSMENT_STATUS } from '@/services/Hub/enums/FiscalDebtInquiry.enum'

import { IRecordCandidate, recordTypeCatalog } from '../../providers/ComplianceContext'
import { fiscalRecordTypeCatalog } from './fiscalRecordTypeCatalog'
import { getFiscalAssessmentLevel } from './getFiscalAssessmentLevel'

interface IBuildCreateFiscalDebtInquiryDTO {
  record: IRecordCandidate
  documentUrl?: string
  canonicalProfileId: string
}

export const buildCreateFiscalDebtInquiryDTO = (
  props: IBuildCreateFiscalDebtInquiryDTO
): CreateFiscalDebtInquiryDTO => {
  const { record, documentUrl, canonicalProfileId } = props

  const recordType = record.recordType as typeof recordTypeCatalog['fiscal'][number]

  const expirationDate = record.documentExpirationDate.toISOString()
  const level = getFiscalAssessmentLevel(recordType)
  const { stateShortName, stateLongName } = fiscalRecordTypeCatalog[recordType]

  return {
    canonicalProfileId,
    creationType: 'USER_INPUT',
    expirationDate,
    level,
    referenceDate: new Date().toISOString(),
    source: 'UNKNOWN',
    status: record.documentStatus as FISCAL_ASSESSMENT_STATUS,
    documentUrl,
    stateShortName,
    stateLongName
  }
}
