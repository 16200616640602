import * as turf from '@turf/turf'

import { axiosGis } from './config/axiosGis.config'

export type TGisAreaNdvi = {
  min: number
  mean: number
  max: number
  area_id: string
  reference_date: string
  cloud_percentage: number
}

export type TGisReviwedSeason = {
  area_id: string
  has_been_checked: boolean
  crops_id: string | null
  duration: number
  end_date: string
  ndvi_peak: number | null
  start_date: string
  duration_pred: number | null
  end_date_pred: string | null
  ndvi_peak_pred: number | null
}

const getAreasInGeometry = async (geometry: turf.Geometry) => {
  const { data } = await axiosGis.post<turf.FeatureCollection>('/api/v2/areas/raw', {
    geometry: geometry
  })
  return data
}

const createArea = async (geometry: turf.Geometry, productive: boolean, checked: boolean) => {
  const { data } = await axiosGis.post<{
    geometry: turf.Geometry
    has_been_checked: boolean
    has_productive_area: boolean
    id: string
  }>('/api/v2/areas', {
    geometry: geometry,
    has_productive_area: productive,
    has_been_checked: checked
  })
  return data
}

const saveFeature = async (
  id: string,
  geometry: turf.Geometry,
  has_productive_area: boolean,
  has_been_checked: boolean
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const response = await axiosGis.put<any>(`api/v2/areas/${id}`, {
    geometry,
    has_productive_area,
    has_been_checked
  })
}

const removeArea = async (areaId: string) => {
  const { data } = await axiosGis.delete(`/api/v2/areas/${areaId}`)
  return data
}

const getAreaNdvi = async (areaId: string) => {
  const { data } = await axiosGis.get<TGisAreaNdvi[]>(`/api/v2/areas/${areaId}/ndvi-history`)
  return data
}

const saveAreaSeasons = async (id: string, seasons: TGisReviwedSeason[]) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const response = await axiosGis.post<any>(`api/v2/areas/${id}/seasons`, {
    area_id: id,
    seasons: seasons
  })
}

export const GisApi = {
  getAreasInGeometry,
  saveFeature,
  removeArea,
  getAreaNdvi,
  saveAreaSeasons,
  createArea
}
