import { ArrowBack } from '@mui/icons-material'
import { colors } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

export type TArrowBackButton = {
  handleBack: () => void
}

export const ArrowBackButton = ({ handleBack }: TArrowBackButton) => {
  return (
    <Box
      sx={{
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        p: '0.25rem 0.5rem',
        gap: '0.25rem',
        color: colors.grey[600],
        '&:hover': {
          cursor: 'pointer',
          color: colors.grey[700]
        }
      }}
      onClick={handleBack}>
      <ArrowBack fontSize="inherit" />
      <Typography variant="button" fontSize="0.8125rem">
        Voltar
      </Typography>
    </Box>
  )
}
