import { axiosHub } from '../config/axiosHub.config'
import {
  FGTS_CREATION_TYPE,
  LABOR_DEBTS_STATUS,
  SLAVERY_OCCURRENCE_CREATION_TYPE
} from './enums/LaborDebts.enum'

export type THubLaborAssessment = {
  referenceDate: Date
  fgtsDebt: FGTSDebtDTO
  laborDebt: LaborDebtDTO
  slaveryOccurrence: SlaveryOccurrenceDTO
}

export type FGTSDebtDTO = {
  id: string
  createdAt?: Date
  updatedAt?: Date
  deletedAt?: Date
  documentUrl?: string
  status?: LABOR_DEBTS_STATUS
  referenceDate: Date
  expirationDate: Date
  canonicalProfileId: string
  creationType: FGTS_CREATION_TYPE
  modifiedByUserId?: string
}

export type LaborDebtDTO = {
  id: string
  createdAt?: Date
  updatedAt?: Date
  deletedAt?: Date
  documentUrl?: string
  status?: LABOR_DEBTS_STATUS
  referenceDate: Date
  expirationDate: Date
  canonicalProfileId: string
  creationType?: string
  modifiedByUserId?: string
}

export type SlaveryOccurrenceDTO = {
  id: string
  createdAt?: Date
  deletedAt?: Date
  updatedAt?: Date
  status?: string
  documentUrl?: string
  referenceDate: Date
  expirationDate: Date
  canonicalProfileId: string
  creationType: SLAVERY_OCCURRENCE_CREATION_TYPE
  modifiedByUserId?: string
}

export type CreateFGTSDebtDTO = Omit<FGTSDebtDTO, 'id'>
export type CreateLaborDebtDTO = Omit<LaborDebtDTO, 'id'>
export type CreateSlaveryOccurrenceDTO = Omit<SlaveryOccurrenceDTO, 'id'>

const getRequest = async (personProfileId: string) => {
  const { data } = await axiosHub.get<THubLaborAssessment>(
    `/api/v1/assessments/labor?personProfileId=${personProfileId}`
  )
  return data
}

const postFGTSDebtRequest = async (body: CreateFGTSDebtDTO) => {
  const { data } = await axiosHub.post<FGTSDebtDTO>('/api/v1/assessments/labor/fgts-debts', body)
  return data
}

const postLaborDebtRequest = async (body: CreateLaborDebtDTO) => {
  const { data } = await axiosHub.post<LaborDebtDTO>('/api/v1/assessments/labor/labor-debts', body)
  return data
}

const postSlaveryOccurrenceRequest = async (body: CreateSlaveryOccurrenceDTO) => {
  const { data } = await axiosHub.post<SlaveryOccurrenceDTO>(
    '/api/v1/assessments/labor/slavery-occurrences',
    body
  )
  return data
}

/**
 * Expects debtInquiryId OR canonicalProfileId as params. If you send both, then the canonicalProfileId will be ignored.
 */
const deleteFGTSDebtRequest = async (params: {
  fgtsDebtId?: string
  canonicalProfileId?: string
}) => {
  if (!params.fgtsDebtId && !params.canonicalProfileId)
    throw new Error('FGTS Debt ID or Canonical Profile ID is required')

  // Rename fgtsDebtId to debtInqueryId, because the API expects the WRONG writing
  const urlParams = params.fgtsDebtId
    ? { fgtsDebtId: params.fgtsDebtId }
    : { canonicalProfileId: params.canonicalProfileId }

  const { data } = await axiosHub.delete<void>('/api/v1/assessments/labor/fgts-debts', {
    params: urlParams
  })
  return data
}

const deleteLaborDebtRequest = async (params: {
  laborDebtId?: string
  canonicalProfileId?: string
}) => {
  if (!params.laborDebtId && !params.canonicalProfileId)
    throw new Error('Labor Debt ID or Canonical Profile ID is required')

  // Rename laborDebtId to debtInqueryId, because the API expects the WRONG writing
  const urlParams = params.laborDebtId
    ? { laborDebtId: params.laborDebtId }
    : { canonicalProfileId: params.canonicalProfileId }

  const { data } = await axiosHub.delete<void>('/api/v1/assessments/labor/labor-debts', {
    params: urlParams
  })
  return data
}

const deleteSlaveryOccurrenceRequest = async (params: {
  slaveryOccurrenceId?: string
  canonicalProfileId?: string
}) => {
  if (!params.slaveryOccurrenceId && !params.canonicalProfileId)
    throw new Error('Slavery Occurrence ID or Canonical Profile ID is required')

  // Rename slaveryOccurrenceId to debtInqueryId, because the API expects the WRONG writing
  const urlParams = params.slaveryOccurrenceId
    ? { slaveryOccurrenceId: params.slaveryOccurrenceId }
    : { canonicalProfileId: params.canonicalProfileId }

  const { data } = await axiosHub.delete<void>('/api/v1/assessments/labor/slavery-occurrences', {
    params: urlParams
  })
  return data
}

export const AssessmentsLabor = {
  getRequest,
  postFGTSDebtRequest,
  postLaborDebtRequest,
  postSlaveryOccurrenceRequest,
  deleteFGTSDebtRequest,
  deleteLaborDebtRequest,
  deleteSlaveryOccurrenceRequest
}
