import { Close } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  FormControlLabel,
  Switch
} from '@mui/material'
import * as turf from '@turf/turf'
import React, { useEffect, useState } from 'react'

import { CustomToggleButton } from '../../../../../components'
import {
  AreasChart,
  CHART_MODE,
  TArea,
  TData
} from '../../../../../components/AreasChart/AreasChartContainer'
import { LatLongCopyButton } from '../../../../../components/LatLongCopyButton/LatLongCopyButton'
import { GisApi } from '../../../../../services/GisApi'
import { getOrderedColor } from '../../../../../shared/constants/orderedColors'
import { TLandField } from '../../EditSeasons'
import { ModalMap } from './components/ModalMap'
import { ModalSidebar } from './components/ModalSidebar'

interface IShareSeasonsModal {
  open: boolean
  data: TData[]
  onClose: () => void
  propertyAreas: TLandField[]
  originAreaId: string
  areas: TArea[]
  shareSeasonsBetweenAreas: (donorAreaId: string, recipientAreasIds: string[]) => void
  selectedAreaCoordinates: turf.helpers.Position | null
  checkedAreas: string[]
  geojson: turf.helpers.FeatureCollection<
    turf.helpers.Geometry | turf.helpers.GeometryCollection,
    turf.helpers.Properties
  > | null
}

export const ShareSeasonsModal = ({
  open,
  onClose,
  propertyAreas,
  originAreaId,
  areas,
  data,
  shareSeasonsBetweenAreas,
  selectedAreaCoordinates,
  geojson,
  checkedAreas
}: IShareSeasonsModal) => {
  const [selectedAreas, setSelectedAreas] = useState<string[]>([])
  const [visualizationType, setVisualizationType] = useState<string>('grafico')
  const [mode, setMode] = useState(CHART_MODE.VISUALIZATION)
  const [lines, setLines] = useState<{ id: string; color: string; data: TData[] }[]>([])
  const [hideSeasonAreas, setHideSeasonAreas] = useState(false)

  useEffect(() => {
    const newLines: { id: string; color: string; data: TData[] }[] = []
    propertyAreas
      .filter(e => e.id == originAreaId || !checkedAreas.includes(e.id))
      .map((propertyArea, index) => {
        GisApi.getAreaNdvi(propertyArea.id).then(res =>
          newLines.push({
            id: propertyArea.id,
            color: getOrderedColor(index),
            data: res.map(({ mean, reference_date }) => ({
              x: new Date(reference_date).getTime(),
              y: mean
            }))
          })
        )
      })
    setLines(newLines)
  }, [propertyAreas, checkedAreas])

  const addToSelectedAreas = (id: string) => {
    const indexOfId = selectedAreas.findIndex(e => e == id)
    console.log(`${typeof id}     ${indexOfId}`)
    if (indexOfId < 0) setSelectedAreas(oldArray => [...oldArray, id])
    else setSelectedAreas(oldArray => oldArray.filter(element => element !== id))
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle display="flex" gap="2rem" alignItems="center">
        <Box flexGrow={1}>
          <Typography variant="h5">Replicar safras</Typography>
          <Typography variant="subtitle1">
            Selecione os talhões com safras similares para replicar a análise
          </Typography>
        </Box>
        <IconButton sx={{ width: '1.5em', height: '1.5em' }} onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <Box
        sx={{
          marginBottom: '0.5rem',
          justifyContent: 'right',
          display: 'flex',
          // gap: '40px',
          marginRight: '0.5rem'
        }}>
        {selectedAreaCoordinates ? (
          <LatLongCopyButton coordinates={selectedAreaCoordinates} />
        ) : null}

        <CustomToggleButton
          sx={{ marginLeft: '40px', marginRight: '25px' }}
          valueState={visualizationType}
          setValueState={setVisualizationType}
          values={['grafico', 'mapa']}
          msgValues={['Gráfico Ndvi', 'Mapa']}
        />
      </Box>

      <DialogContent sx={{ display: 'flex', minHeight: '325px' }}>
        <ModalSidebar
          areas={propertyAreas
            .map((e, i) => {
              return { ...e, index: i + 1 }
            })
            .filter(e => e.id == originAreaId || !checkedAreas.includes(e.id))}
          originAreaId={originAreaId}
          selectedAreas={selectedAreas}
          addToSelectedAreas={addToSelectedAreas}
          colors={lines.map(line => {
            return { id: line.id, value: line.color }
          })}
        />
        {visualizationType === 'grafico' ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end'
            }}>
            <FormControlLabel
              control={
                <Switch
                  checked={hideSeasonAreas}
                  onChange={() => setHideSeasonAreas(old => !old)}
                />
              }
              label="Esconder safras"
              labelPlacement="start"
            />
            <AreasChart
              data={data}
              areas={areas}
              setAreas={() => {
                return
              }}
              selectedAreas={[]}
              setSelectedAreas={() => {
                return
              }}
              mode={mode}
              disableEditing
              setMode={setMode}
              lines={lines.filter(line => selectedAreas.indexOf(line.id) >= 0)}
              height="40vh"
              width="60vw"
              hideAreas={hideSeasonAreas}
            />
          </Box>
        ) : (
          <ModalMap
            geojson={
              geojson
                ? {
                    ...geojson,
                    features: geojson.features.filter(
                      e =>
                        e.properties &&
                        (e.properties.area_id == originAreaId ||
                          (e.properties && !checkedAreas.includes(e.properties.area_id)))
                    )
                  }
                : null
            }
            setSelectedAreas={setSelectedAreas}
            selectedAreas={selectedAreas}
            addToSelectedAreas={addToSelectedAreas}
            originAreaId={originAreaId}
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button
          variant="discreet"
          color="info"
          onClick={() => {
            if (confirm('Realmente deseja replicar as áreas?')) {
              shareSeasonsBetweenAreas(originAreaId, selectedAreas)
              onClose()
              setSelectedAreas([])
            }
          }}>
          Replicar
        </Button>
        <Button
          variant="discreet"
          onClick={() => {
            onClose()
            setSelectedAreas([])
          }}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
