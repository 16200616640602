import { AxiosError } from 'axios'
import { useContext, useState } from 'react'

import { postCriminalRecordApi } from '../ComplianceCriminal/apis/postCriminalRecordApi'
import { postEnvironmentalRecordApi } from '../ComplianceEnvironmental/apis/postEnvironmentalRecordApi'
import { postFiscalRecordApi } from '../ComplianceFiscal/apis/postFiscalRecordApi'
import { postLaborRecordApi } from '../ComplianceLabor/apis/postLaborDebtApi'
import { COMPLIANCE_TASK_TYPE } from '../enums/ComplianceTaskType'
import { ComplianceContext, IRecordCandidate } from '../providers/ComplianceContext'

export const useCreateRecordsBackend = (complianceTaskType: COMPLIANCE_TASK_TYPE) => {
  const {
    personProfile: { canonicalProfileId, id: personProfileId }
  } = useContext(ComplianceContext)

  const [error, setError] = useState<AxiosError | null>()
  const [isRequesting, setIsRequesting] = useState<boolean>()
  const [response, setResponse] = useState<unknown[]>()
  const [success, setSuccess] = useState<boolean>()

  const resetStates = () => {
    setError(undefined)
    setIsRequesting(undefined)
    setResponse(undefined)
    setSuccess(undefined)
  }

  const bootstrap = async (records: IRecordCandidate[]) => {
    try {
      resetStates()
      setIsRequesting(true)
      let res: unknown[] = []

      if (!canonicalProfileId || !personProfileId) return

      if (complianceTaskType === COMPLIANCE_TASK_TYPE.criminal) {
        res = await Promise.all(
          records.map(record =>
            postCriminalRecordApi({
              canonicalProfileId,
              personProfileId,
              record
            })
          )
        )
      } else if (complianceTaskType === COMPLIANCE_TASK_TYPE.environmental) {
        res = await Promise.all(
          records.map(record =>
            postEnvironmentalRecordApi({
              canonicalProfileId,
              personProfileId,
              record
            })
          )
        )
      } else if (complianceTaskType === COMPLIANCE_TASK_TYPE.fiscal) {
        res = await Promise.all(
          records.map(record =>
            postFiscalRecordApi({
              canonicalProfileId,
              personProfileId,
              record
            })
          )
        )
      } else if (complianceTaskType === COMPLIANCE_TASK_TYPE.labor) {
        res = await Promise.all(
          records.map(record =>
            postLaborRecordApi({
              canonicalProfileId,
              personProfileId,
              record
            })
          )
        )
      }

      setError(null)
      setResponse(res)
      setSuccess(true)
    } catch (err) {
      if (err instanceof AxiosError) {
        alert(err.response?.data)
        setError(err)
      }

      setSuccess(false)
    } finally {
      setIsRequesting(false)
    }
  }

  const createRecordsBackend = (records: IRecordCandidate[]) => {
    bootstrap(records)
  }

  return { createRecordsBackend, error, isRequesting, response, success }
}
