import { TData } from '@components'
import { HubApi } from '@services'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { TLandField } from '../EditSeasons'

export const useFetchPropertyNdvi = () => {
  const { property_id: propertyId } = useParams()

  const [landFields, setLandFields] = useState<TLandField[]>([])
  const [ndviCatalog, setNdviCatalog] = useState<Record<string, TData[]>>({})
  const [isLoading, setIsFetching] = useState(true)

  const fetchNdvi = (landFieldId: string) => {
    for (const key in ndviCatalog) {
      if (key === landFieldId) return ndviCatalog[key]
    }
    return []
  }

  const fetchNdviDataApi = async (landFieldId: string) => {
    const response = await HubApi.getAreaNdvi(landFieldId)
    const data = response.map(({ mean, referenceDate }) => ({
      x: new Date(referenceDate).getTime(),
      y: mean
    }))
    return { [landFieldId]: data }
  }

  const bootstrap = async () => {
    if (!propertyId) return

    const hubAreas = await HubApi.getAreas(propertyId)
    setLandFields(
      hubAreas.map(hubArea => ({
        id: hubArea.id,
        name: hubArea.name,
        geometry: hubArea.boundary
      }))
    )

    const ndvis = await Promise.all(hubAreas.map(({ id }) => fetchNdviDataApi(id)))
    setNdviCatalog(ndvis.reduce((acc, curr) => Object.assign(acc, curr), {}))
    setIsFetching(false)
  }

  useEffect(() => {
    bootstrap()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { ndviCatalog, landFields, fetchNdvi, isLoading }
}
