import { EnvironmentalAssessmentDTO } from '@/services/Hub/AssessmentsEnvironmental'
import { HubApi } from '@services'
import { AxiosError } from 'axios'
import { useCallback, useContext, useEffect, useState } from 'react'

import { ComplianceContext } from '../../providers/ComplianceContext'

export const useEnvironmentalAssessment = (params: { personProfileId: string }) => {
  const [error, setError] = useState<AxiosError | null>()
  const [isRequesting, setIsRequesting] = useState(true)
  const [response, setResponse] = useState<EnvironmentalAssessmentDTO>()
  const { resetCounter } = useContext(ComplianceContext)

  const resetStates = () => {
    setError(undefined)
    setIsRequesting(true)
    setResponse(undefined)
  }

  const fetchEnvironmentalAssessmentApi = useCallback(async () => {
    try {
      const res = await HubApi.AssessmentsEnvironmental.getRequest(params.personProfileId)
      setResponse(res)
      setError(null)
    } catch (err) {
      if (err instanceof AxiosError) setError(err)
    } finally {
      setIsRequesting(false)
    }
  }, [params.personProfileId])

  useEffect(() => {
    resetStates()
    fetchEnvironmentalAssessmentApi()
  }, [fetchEnvironmentalAssessmentApi, resetCounter])

  return { response, isRequesting, error }
}
