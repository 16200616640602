import * as turf from '@turf/turf'

import { Assessments } from './Hub/Assessments'
import { AssessmentsCriminal } from './Hub/AssessmentsCriminal'
import { AssessmentsEnvironmental } from './Hub/AssessmentsEnvironmental'
import { AssessmentsFiscal } from './Hub/AssessmentsFiscal'
import { AssessmentsLabor } from './Hub/AssessmentsLabor'
import { ProfileAnalysisRequest } from './Hub/ProfileAnalysisRequests'
import { axiosHub } from './config/axiosHub.config'

export type THubProperty = {
  id: string
  name: string
}

export type THubAreaNdvi = {
  referenceDate: string
  min: number
  mean: number
  max: number
}

export type THubDetectedSeason = {
  crop_name: string
  crops_id?: string
  area_id: string
  area_name: string
  start_date: string
  end_date: string
  validation: string
}

export type THubArea = {
  id: string
  name: string
  boundary: turf.Geometry
}

const getAreaNdvi = async (areaId: string) => {
  const { data } = await axiosHub.get<THubAreaNdvi[]>(
    `/api/v1/areas/ndvi?areaId=${areaId}&minReferenceDate=2017-01-01T00:00:00.000Z`
  )
  return data
}

const getDetectedSeasons = async (propertyId: string) => {
  const { data } = await axiosHub.get<THubDetectedSeason[]>(
    `/api/v1/seasons/detected-seasons?propertyId=${propertyId}`
  )
  return data
}

const getAreas = async (propertyId: string) => {
  const { data } = await axiosHub.get<THubArea[]>(`/api/v1/areas?propertyId=${propertyId}`)
  return data
}

const getProperty = async (propertyId: string) => {
  const { data } = await axiosHub.get<THubProperty>(`/api/v1/properties/${propertyId}`)
  return data
}

export const HubApi = {
  getProperty,
  getAreaNdvi,
  getDetectedSeasons,
  getAreas,
  AssessmentsCriminal,
  AssessmentsEnvironmental,
  AssessmentsFiscal,
  AssessmentsLabor,
  Assessments,
  ProfileAnalysisRequest
}
