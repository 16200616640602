import { MouseEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { logout } from '../../redux/authenticator'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { RootState } from '../../redux/store'
import { HeaderView } from './HeaderView'

const settings = ['Sair'] as const

const appMenus = [
  {
    menu: 'radar',
    submenus: ['Revisar pedidos']
  },
  {
    menu: 'bender',
    submenus: ['Acompanhar execução', 'Revisar tarefas']
  }
] as const

export type TSettings = typeof settings
export type TAppMenus = typeof appMenus
export type TAppMenuLabels = typeof appMenus[number]['menu']

export type TAppPages =
  | typeof appMenus[number]['menu']
  | typeof settings[number]
  | typeof appMenus[number]['submenus'][number]
  | 'Home'

export const Header = () => {
  const navigate = useNavigate()
  const userInfo = useAppSelector((state: RootState) => state.authenticator.user.info)
  const dispatch = useAppDispatch()
  const headerMode = useAppSelector((state: RootState) => state.header.mode)

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)
  const [anchorElPage, setAnchorElPage] = useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)

  const [menuToShowDrop, setMenuToShowDrop] = useState<TAppMenuLabels>()

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenPageMenu = (event: MouseEvent<HTMLElement>, openMenu: TAppMenuLabels) => {
    setAnchorElPage(event.currentTarget)
    setMenuToShowDrop(openMenu)
  }
  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }
  const handleClosePageMenu = () => {
    setAnchorElPage(null)
    setMenuToShowDrop(undefined)
  }
  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleNavigate = (page: TAppPages) => {
    handleCloseNavMenu()
    handleClosePageMenu()
    if (page === 'Home') navigate('/')
    if (page === 'Acompanhar execução') navigate('/bender/executions')
    if (page === 'Revisar tarefas') navigate('/bender/tasks')
    if (page === 'Revisar pedidos') navigate('/radar')
    if (page === 'Sair') dispatch(logout())
  }

  return (
    <HeaderView
      anchorElNav={anchorElNav}
      anchorElPage={anchorElPage}
      anchorElUser={anchorElUser}
      handleCloseNavMenu={handleCloseNavMenu}
      handleClosePageMenu={handleClosePageMenu}
      handleCloseUserMenu={handleCloseUserMenu}
      handleNavigate={handleNavigate}
      handleOpenNavMenu={handleOpenNavMenu}
      handleOpenPageMenu={handleOpenPageMenu}
      handleOpenUserMenu={handleOpenUserMenu}
      appMenus={appMenus}
      settings={settings}
      headerMode={headerMode}
      userInfo={userInfo}
      menuToShowDrop={menuToShowDrop}
    />
  )
}
