import { FlexBox } from '@components'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField
} from '@mui/material'
import { useState } from 'react'

export interface IRejectionDialogProps extends DialogProps {
  handleConfirm: (reason: string) => void
  handleCancel: () => void
}

export const RejectionDialog = (props: IRejectionDialogProps) => {
  const { handleCancel, handleConfirm, ...restProps } = props

  const [rejectionReason, setRejectionReason] = useState('')

  return (
    <Dialog onClose={handleCancel} {...restProps}>
      <DialogTitle>Rejeitar Tarefa</DialogTitle>
      <DialogContent>
        <FlexBox sx={{ p: '1rem', minWidth: '768px' }}>
          <TextField
            onChange={event => setRejectionReason(event.target.value)}
            label="Motivo da rejeição"
            variant="standard"
            sx={{ width: '100%' }}
          />
        </FlexBox>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} variant="discreet" sx={{ minWidth: '150px' }}>
          Cancelar
        </Button>
        <Button
          onClick={() => handleConfirm(rejectionReason)}
          variant="discreet"
          color="error"
          sx={{ minWidth: '150px' }}>
          Rejeitar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
