/* eslint-disable @typescript-eslint/no-empty-function */

import { COMPLIANCE_TASK_TYPE } from '../../enums/ComplianceTaskType'
import { IComplianceContext } from '../ComplianceContext'

export const emptyComplianceContext: IComplianceContext = {
  personProfile: {
    canonicalProfileId: undefined,
    govRegCode: undefined,
    govRegType: undefined,
    id: undefined,
    name: undefined
  },
  taskType: COMPLIANCE_TASK_TYPE.criminal, // This line was added because taskType cannot be undefined. This value will be discarded.
  recordCandidates: [],
  addRecordCandidate: () => {},
  removeRecordCandidate: () => {},
  deleteRecords: [],
  addDeleteRecord: () => {},
  hasPendingModifications: false,
  resetStates: () => {},
  resetCounter: 0
}
