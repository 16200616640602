/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { colors, Box } from '@mui/material'
import bbox from '@turf/bbox'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import { FeatureCollection, point } from '@turf/helpers'
import * as turf from '@turf/turf'
import mapboxgl from 'mapbox-gl'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Layer, MapRef, Source } from 'react-map-gl'
import ResizePanel from 'react-resize-panel-ts'
// eslint-disable-next-line import/no-named-as-default
import WebMercatorViewport from 'viewport-mercator-project'

import { EditAreasMap } from '../../../../../components/EditAreasMap'
import { useAppSelector } from '../../../../../redux/hooks'
import { RootState } from '../../../../../redux/store'
import { BenderApi } from '../../../../../services/BenderApi'
import { BENDER_TASK_STATUS } from '../../../../../services/enums/bender.enum'
import AreasList from './components/AreasList/AreasListContainer'
import { ArrowBackButton } from './components/ArrowBackButton'

// @ts-ignore
mapboxgl.workerClass =
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

interface IMap {
  geoJsonId: string
  closeMap: () => void
}

export const Map = ({ geoJsonId, closeMap }: IMap) => {
  const [geoJson, setGeoJson] = useState<FeatureCollection | undefined | null>(null)
  const [intersections, setIntersections] = useState<FeatureCollection>({
    features: [],
    type: 'FeatureCollection'
  })
  const [intersectionsVisibility, setIntersectionsVisibility] = useState(true)
  const [editingIndex, setEditingIndex] = useState<number | null>(null)
  const [highlightedFeatId, setHighlightedFeatId] = useState<number | null>(null)
  const [reRenderIntersec, setReRenderIntersec] = useState(false)
  const [drawRef, setDrawRef] = useState<MapboxDraw | null>(null)
  const [isSendingReview, setIsSendingReview] = useState(false)
  const pageOpeningTime = useRef(new Date())
  const [highlightedFeatCoordinates, setHighlightedFeatCoordinates] =
    useState<turf.helpers.Position | null>(null)

  const mapRef = useRef<MapRef>(null)

  const userInfo = useAppSelector((state: RootState) => state.authenticator.user.info)

  const getBoundsForPoints = (
    polygon: turf.FeatureCollection | turf.Geometry | turf.GeometryCollection
  ) => {
    const cornersLongLat = bbox(polygon)
    const viewport = new WebMercatorViewport({
      width: 800,
      height: 600
    }).fitBounds(
      [
        [cornersLongLat[0], cornersLongLat[1]],
        [cornersLongLat[2], cornersLongLat[3]]
      ],
      { padding: 40 }
    )
    const { longitude, latitude, zoom } = viewport
    return { center: { lon: longitude, lat: latitude }, zoom }
  }

  const calculateIntersections = () => {
    if (!geoJson) return
    const intersectionsFeatures: turf.Feature<turf.Polygon | turf.MultiPolygon>[] = []
    geoJson.features.forEach((feature, index) => {
      for (let i = index + 1; i < geoJson.features.length; i++) {
        const intersection = turf.intersect(
          feature as turf.Feature<turf.Polygon>,
          geoJson.features[i] as turf.Feature<turf.Polygon>
        )
        intersection && intersectionsFeatures.push(intersection)
      }
    })
    setIntersections({
      type: 'FeatureCollection',
      features: intersectionsFeatures
    })
  }
  const getClickedLayerId = (long: number, lat: number): number | null => {
    const pt = point([long, lat])
    let id = null
    geoJson?.features?.forEach(feature => {
      if (feature && booleanPointInPolygon(pt, feature.geometry as turf.Polygon)) {
        id = feature.id
      }
    })
    return id
  }

  const handleSwitchChange = (featureId: number) => {
    setGeoJson(value => {
      if (!value) return
      return {
        ...value,
        features: value?.features?.map(feature => {
          if (feature.id === featureId && feature.properties)
            feature.properties.productive = feature?.properties?.productive ? 0 : 1
          return feature
        })
      }
    })
  }

  const handleBack = () => {
    setHighlightedFeatId(null)
    closeMap()
  }

  const handleFinalizeReview = () => {
    if (!geoJson || intersections.features.length) {
      setReRenderIntersec(!reRenderIntersec)
      alert('Erro, verifique se há interseções entre as áreas desenhadas.')
      return
    }
    setIsSendingReview(true)
    const geoJsonOfProdutiveAreas = {
      ...geoJson,
      features: geoJson.features.filter(feature => feature?.properties?.productive)
    }
    BenderApi.saveTasks(geoJsonId, {
      completedBy: userInfo.email,
      completionInput: geoJsonOfProdutiveAreas,
      id: geoJsonId,
      status: BENDER_TASK_STATUS.SUCCEEDED,
      startedAt: pageOpeningTime.current
    })
      .then(() => {
        setIsSendingReview(false)
        closeMap()
      })
      .catch(err => {
        setIsSendingReview(false)
        console.error(err)
      })
  }

  const handleToggleIntersectionsVisibility = () => {
    setIntersectionsVisibility(value => {
      if (!value && intersections.features.length)
        mapRef.current?.flyTo({
          ...getBoundsForPoints(intersections),
          duration: 400
        })
      return !value
    })
  }

  useEffect(() => {
    BenderApi.getGeoJson({ id: geoJsonId }).then(geoJson => {
      setGeoJson(geoJson)
    })
  }, [geoJsonId])

  useEffect(() => {
    const highLightedFeat = geoJson?.features.find(feature => feature.id == highlightedFeatId)
    if (highLightedFeat) {
      setHighlightedFeatCoordinates(turf.centroid(highLightedFeat).geometry.coordinates)
    } else {
      setHighlightedFeatCoordinates(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedFeatId])

  useEffect(() => {
    const map = mapRef.current?.getMap()
    if (geoJson && geoJson.features.length && map) {
      // setViewportOptions(getBoundsForPoints(geoJson));
      map.flyTo({
        ...getBoundsForPoints(geoJson),
        duration: 400,
        padding: 20
      })
    }
    if (geoJson && geoJson.features.length === 0) alert('Erro: geometria vazia')
  }, [geoJson, mapRef])

  useEffect(() => {
    if (geoJson)
      if (highlightedFeatId !== null) {
        // geoJson.features.find(feature.id === highlightedFeatId)

        geoJson.features.forEach(feature => {
          if (feature.id == highlightedFeatId)
            mapRef.current?.getMap().flyTo({
              ...getBoundsForPoints(feature.geometry),
              duration: 400,
              padding: 20
            })
        })
      }
    // else {
    //   setViewportOptions(getBoundsForPoints(geoJson));
    // }
  }, [highlightedFeatId, geoJson])

  const draw = new MapboxDraw({
    displayControlsDefault: false,
    controls: {}
  })

  const cancelEditing = () => {
    if (drawRef && mapRef.current) {
      mapRef.current.getMap().removeControl(drawRef)
      setEditingIndex(null)
      setDrawRef(null)
    }
  }

  const saveEditingArea = () => {
    if (editingIndex !== null && drawRef !== null) {
      const newGeoJson = geoJson
      const featCollection = drawRef.getAll()
      if (newGeoJson)
        newGeoJson.features[editingIndex] = featCollection
          .features[0] as turf.Feature<turf.Geometry>
      setGeoJson(newGeoJson)
      cancelEditing()
      calculateIntersections()
    }
  }

  const removeArea = (featId: turf.helpers.Id | undefined) => {
    if (geoJson?.features) {
      const features: turf.FeatureCollection<turf.Geometry | turf.GeometryCollection>['features'] =
        []
      geoJson.features.forEach(feat => {
        if (feat.id !== featId) features.push(feat)
      })
      setGeoJson({ ...geoJson, features: features })
    }
  }

  const removeAreaByFeatureId = (featId: number) => {
    if (geoJson?.features) {
      const features: turf.FeatureCollection<turf.Geometry | turf.GeometryCollection>['features'] =
        []
      geoJson.features.forEach(feat => {
        if (feat.id != featId) features.push(feat)
      })
      setGeoJson({ ...geoJson, features: features })
    }
  }

  // useEffect for opening edit area mode
  useEffect(() => {
    const map = mapRef.current
    if (map && geoJson) {
      if (editingIndex !== null && drawRef === null) {
        map.getMap().addControl(draw)
        setDrawRef(draw)
        if (geoJson.features[editingIndex]) {
          draw.set({
            // @ts-ignore
            features: [geoJson.features[editingIndex]],
            type: 'FeatureCollection'
          })
          draw.changeMode(
            // @ts-ignore
            'direct_select',
            {
              featureId: geoJson.features[editingIndex].id
            }
          )
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef.current, editingIndex, geoJson])

  const pressDelete = (event: KeyboardEvent) => {
    if (event.key === 'Delete' && drawRef !== null) {
      drawRef.trash()
    } else if (event.key === 'Delete' && highlightedFeatId !== null && geoJson) {
      if (confirm('Deseja remover a área selecionada?')) {
        removeAreaByFeatureId(highlightedFeatId)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', pressDelete)
    return () => {
      window.removeEventListener('keydown', pressDelete)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedFeatId, geoJson, drawRef])

  return (
    <Box
      sx={{
        height: '100%',
        width: '100vw',
        display: 'flex'
      }}>
      {/* @ts-ignore */}
      <ResizePanel
        direction="e"
        handleClass="customHandle"
        borderClass="customResizeBorder"
        style={{
          minWidth: '270px',
          height: '100%'
        }}>
        <Box
          sx={{
            width: '100%',
            overflow: 'scroll',
            bgcolor: colors.grey[100]
          }}>
          <ArrowBackButton handleBack={handleBack} />
          <AreasList
            geoJson={geoJson}
            setGeoJson={setGeoJson}
            highlightedFeatId={highlightedFeatId}
            setHighlightedFeatId={setHighlightedFeatId}
            editingIndex={editingIndex}
            setEditingIndex={setEditingIndex}
            saveEditingArea={saveEditingArea}
            cancelEditing={cancelEditing}
            removeArea={removeArea}
          />
        </Box>
      </ResizePanel>
      <EditAreasMap
        mapRef={mapRef}
        onMapClick={props => {
          props.originalEvent.preventDefault()
          if (editingIndex === null) {
            const id = getClickedLayerId(props.lngLat.lng, props.lngLat.lat)
            setHighlightedFeatId(id)
          }
        }}
        onMapDblClick={props => {
          props.originalEvent.preventDefault()
          if (editingIndex === null) {
            const id = getClickedLayerId(props.lngLat.lng, props.lngLat.lat)
            setHighlightedFeatId(id)
            if (id) handleSwitchChange(id)
          }
        }}
        editingIndex={editingIndex}
        intersections={intersections}
        intersectionsVisibility={intersectionsVisibility}
        isLoadingSubmit={isSendingReview}
        onSubmit={() => {
          confirm('Deseja finalizar a revisão?') && handleFinalizeReview()
        }}
        highlightedFeatCoordinates={highlightedFeatCoordinates}
        handleToggleIntersectionsVisibility={handleToggleIntersectionsVisibility}>
        <>
          {useMemo(() => {
            return geoJson ? (
              // @ts-ignore
              geoJson.features.map((feature, index) => {
                const isProductive = feature?.properties?.productive === 1
                if (editingIndex !== index)
                  return (
                    <Source
                      key={feature.id}
                      id={`area${feature.id}`}
                      type="geojson"
                      // @ts-ignore
                      data={feature}>
                      <Layer
                        id={`layer${feature.id}`}
                        type={'line'}
                        source={`area${feature.id}`}
                        paint={{
                          'line-color': isProductive ? colors.blue[500] : colors.deepOrange[500],
                          'line-width': 2
                        }}
                      />
                      {highlightedFeatId == feature.id ? (
                        <Layer
                          id={`fill${feature.id}`}
                          type={'fill'}
                          source={`area${feature.id}`}
                          paint={{
                            'fill-color': isProductive ? colors.blue[500] : colors.deepOrange[500],
                            'fill-opacity': 0.3
                          }}
                        />
                      ) : (
                        <Layer
                          id={`fill${feature.id}`}
                          type={'fill'}
                          source={`area${feature.id}`}
                          paint={{
                            'fill-color': isProductive ? colors.blue[500] : colors.deepOrange[500],
                            'fill-opacity': 0.05
                          }}
                        />
                      )}
                    </Source>
                  )
                return <></>
              })
            ) : (
              <></>
            )
          }, [geoJson, highlightedFeatId, editingIndex])}
          {intersections.features.length && intersectionsVisibility && (
            <Source
              id={'intersections'}
              type="geojson"
              // @ts-ignore
              data={intersections}>
              <Layer
                id={'layer-intersectins'}
                type={'line'}
                source={'intersections'}
                paint={{
                  'line-color': colors.red[500],
                  'line-width': 2
                }}
              />
              <Layer
                id={'fill-intersectins'}
                type={'fill'}
                source={'intersections'}
                paint={{
                  'fill-color': colors.red[500],
                  'fill-opacity': 0.7
                }}
              />
            </Source>
          )}
        </>
      </EditAreasMap>
    </Box>
  )
}
